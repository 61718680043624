import {Injectable} from '@angular/core';
import {AlertController} from '@ionic/angular';

import {Events} from './events.service';

@Injectable({
	providedIn: 'root'
})
export class AlertsService {

	constructor(private alertCtrl: AlertController, private events: Events) {
	}

	async errorAlert(header, message) {
		const alert = await this.alertCtrl.create({
			cssClass: 'custom-alert',
			buttons: [
				{
					text: 'Fermer',
					cssClass: 'desktop-text-style-2 mobile-text-style-2 button-validate-alert',
					handler: data => {
					}
				}
			],
			backdropDismiss: false,
		});
		if (header != null) {
			alert.header = header;
		} else {
		}
		if (message != null) {
			alert.message = message;
		}
		await alert.present();
		await alert.onDidDismiss();
	}

	async messageAlert(header, message) {
		const alert = await this.alertCtrl.create({
			cssClass: 'custom-alert',
			buttons: [
				{
					text: 'OK',
					cssClass: 'desktop-text-style-2 mobile-text-style-2 button-validate-alert',
					handler: data => {
					}
				}
			],
			backdropDismiss: false,
		});
		if (header != null) {
			alert.header = header;
		} else {
		}
		if (message != null) {
			alert.message = message;
		}
		await alert.present();
		await alert.onDidDismiss();
	}

	async confirmationAlert(header, message, buttonTextConfirmation, buttonTextAnnulation = 'Annuler') {
		let result = null;
		const alert = await this.alertCtrl.create({
			cssClass: 'custom-alert',
			buttons: [
				{
					text: buttonTextAnnulation,
					cssClass: 'desktop-text-style-33 mobile-text-style-23 button-cancel-alert',
					handler: data => {
						result = false;
					}
				},
				{
					text: buttonTextConfirmation,
					cssClass: 'desktop-text-style-2 mobile-text-style-2 button-validate-alert',
					handler: data => {
						result = true;
					}
				}
			],
			backdropDismiss: false,
		});
		if (header != null) {
			alert.header = header;
		}
		if (message != null) {
			alert.message = message;
		}
		await alert.present();
		await alert.onDidDismiss();
		return result;
	}

	async confirmationWithPasswordInputAlert(header, message, buttonTextConfirmation) {
		let result = null;
		const alert = await this.alertCtrl.create({
			cssClass: 'custom-alert custom-alert-with-input',
			inputs: [
				{
					name: 'input',
					placeholder: 'Mot de passe',
					type: 'password'
				}
			],
			buttons: [
				{
					text: 'Annuler',
					cssClass: 'desktop-text-style-33 mobile-text-style-23 button-cancel-alert',
					handler: data => {
						result = {confirmed: false};
					}
				},
				{
					text: buttonTextConfirmation,
					cssClass: 'desktop-text-style-2 mobile-text-style-2 button-validate-alert',
					handler: data => {
						result = {confirmed: true, input: data != null ? data.input : null};
					}
				}
			],
			backdropDismiss: false,
		});
		if (header != null) {
			alert.header = header;
		}
		if (message != null) {
			alert.message = message;
		}
		await alert.present();
		await alert.onDidDismiss();
		return result;
	}

	async inputPasswordAlert(header, message, buttonTextConfirmation) {
		let result = null;
		const alert = await this.alertCtrl.create({
			cssClass: 'custom-alert custom-alert-with-input',
			inputs: [
				{
					name: 'input1',
					type: 'password',
					placeholder: 'Nouveau mot de passe'
				},
				{
					name: 'input2',
					type: 'password',
					placeholder: 'Ressaisir nouveau mot de passe'
				}
			],
			buttons: [
				{
					text: 'Annuler',
					cssClass: 'desktop-text-style-33 mobile-text-style-23 button-cancel-alert',
					handler: data => {
						result = {confirmed: false};
					}
				},
				{
					text: buttonTextConfirmation,
					cssClass: 'desktop-text-style-2 mobile-text-style-2 button-validate-alert',
					handler: data => {
						if (data.input1 === '' || data.input2 === '') {
							this.errorAlert('Changement de mot de passe', 'Le mot de passe ne peut pas être vide.');
							return false;
						} else if (data.input1 !== data.input2) {
							this.errorAlert('Changement de mot de passe', 'Les mots de passe ne correspondent pas.');
							return false;
						} else {
							result = {confirmed: true, data: data.input1};
						}
					}
				}
			],
			backdropDismiss: false,
		});
		if (header != null) {
			alert.header = header;
		}
		if (message != null) {
			alert.message = message;
		}
		await alert.present();
		await alert.onDidDismiss();
		return result;
	}

	async multipleChoicesAlert(header, message, choices) {
		let result = null;
		const buttons = [];
		for (const i in choices) {
			buttons.push(
				{
					text: choices[i].text,
					cssClass: 'desktop-text-style-2 mobile-text-style-2 button-validate-alert',
					handler: data => {
						result = choices[i].id;
					}
				}
			);
		}
		buttons.push(
			{
				text: 'Annuler',
				cssClass: 'desktop-text-style-33 mobile-text-style-23 button-cancel-alert',
				handler: data => {
				}
			}
		);
		const alert = await this.alertCtrl.create({
			cssClass: 'custom-alert custom-alert-multiple-choices',
			buttons: buttons,
			backdropDismiss: false,
		});
		if (header != null) {
			alert.header = header;
		}
		if (message != null) {
			alert.message = message;
		}
		await alert.present();
		await alert.onDidDismiss();
		if (result) {
			return result;
		} else {
			throw 'ERROR';
		}
	}

	async leaveFormAlert(header, message) {
		let result = null;
		const alert = await this.alertCtrl.create({
			cssClass: 'custom-alert',
			buttons: [
				{
					text: 'Annuler',
					cssClass: 'desktop-text-style-33 mobile-text-style-23 button-cancel-alert',
					handler: data => {
					}
				},
				{
					text: 'Quitter sans sauvegarder',
					cssClass: 'desktop-text-style-2 mobile-text-style-2 button-leave-form-alert',
					handler: data => {
						result = false;
					}
				},
				{
					text: 'Sauvegarder et quitter',
					cssClass: 'desktop-text-style-2 mobile-text-style-2 button-leave-form-alert',
					handler: data => {
						result = true;
					}
				}
			],
			backdropDismiss: false,
		});
		if (header != null) {
			alert.header = header;
		}
		if (message != null) {
			alert.message = message;
		}
		await alert.present();
		await alert.onDidDismiss();
		if (result != null) {
			return result;
		} else {
			throw '';
		}
	}

	async logoutAlert(title) {
		const alert = await this.alertCtrl.create({
			header: title,
			message: 'Vous allez être déconnecté(e)',
			cssClass: 'custom-alert',
			buttons: [
				{
					text: 'OK',
					cssClass: 'desktop-text-style-2 mobile-text-style-2 button-validate-alert',
					handler: data => {
						this.events.publish('logout');
					}
				}
			],
			backdropDismiss: false,
		});
		await alert.present();
		await alert.onDidDismiss();
	}

	getErrorMessage(errorCode) {
		let errorMessage = 'Erreur';

		switch (errorCode) {
			case '[jwt_auth] invalid_username' :
				errorMessage = 'L\'identifiant est incorrect';
				break;
			case 'USER_UNKNOWN' :
				errorMessage = 'L\'identifiant est inconnu';
				break;
			case '[jwt_auth] incorrect_password' :
				errorMessage = 'Le mot de passe est incorrect';
				break;
			case 'USER_INACTIVE' :
				errorMessage = 'Ce compte n\'est pas actif';
				break;
			case 401 :
				errorMessage = 'Identifiant ou mot de passe incorrect';
				break;
			case 403 :
				errorMessage = 'Vous n\'avez pas l\'autorisation d\'effectuer cette action';
				break;
			case 502 :
				errorMessage = 'Une autre personne s\'est connectée avec ce compte';
				break;
			case 'TOKEN' :
				errorMessage = 'Une erreur est survenue';
				break;
			case 'NO_NETWORK' :
				errorMessage = 'Aucune connexion';
				break;
			case 'FORM_LOADING' :
				errorMessage = 'Erreur lors du chargement du formulaire';
				break;
			case 'TASK_LOADING' :
				errorMessage = 'Erreur lors du chargement de la tâche';
				break;
			case 'FORM_DUPLICATE' :
				errorMessage = 'Erreur lors de la duplication du formulaire';
				break;
			case 'FORM_PDF' :
				errorMessage = 'Erreur lors de la génération du PDF';
				break;
			case 'FORM_UNKNOWN' :
				errorMessage = 'Type de formulaire inconnu';
				break;
			case 'TASK_UNKNOWN' :
				errorMessage = 'Type de tâche inconnu';
				break;
			case 'PDF_FORM_UNKNOWN' :
				errorMessage = 'Pas de PDF pour ce type de formulaire';
				break;
			case 'OUVERTURE_AFFAIRE_UNKNOWN':
				errorMessage = 'Ouverture affaire inconnue';
				break;
			case 'TASK_CREATE' :
				errorMessage = 'Erreur lors de la création de la tâche';
				break;
			case 'CAMERA_DENIED' :
				errorMessage = 'Vous n\'avez pas authorisé l\'accès à votre appareil photo. Vous pouvez changer cette authorisation dans les réglages de votre téléphone.';
				break;
			case 'LIBRARY_DENIED' :
				errorMessage = 'Vous n\'avez pas authorisé l\'accès à votre bibliothèque de photos. Vous pouvez changer cette authorisation dans les réglages de votre téléphone.';
				break;
			case 'AFFAIRE_NOT_FOUND':
				errorMessage = 'Ce numéro d\'affaire n\'existe pas ou n\'a pas pu être vérifié. Vous ne pouvez pas sauvegarder ou terminer ce document sans un numéro d\'affaire vérifié.';
				break;
			case 1 :
				errorMessage = 'Une connexion est requise pour pouvoir effectuer l\'action';
				break;
			case 2 :
				errorMessage = 'Action non autorisée';
				break;
			case 3 :
				errorMessage = 'Utilisateur inconnu';
				break;
			case 4 :
				errorMessage = 'Profil manquant';
				break;
			case 5 :
				errorMessage = 'Profil inconnu';
				break;
			case 6 :
				errorMessage = 'Adresse mail invalide';
				break;
			case 7 :
				errorMessage = 'Adresse mail déjà utilisée';
				break;
			case 8 :
				errorMessage = 'Erreur lors du changement d\'adresse email';
				break;
			case 9 :
				errorMessage = 'Erreur lors du changement de mot de passe';
				break;
			case 'FORM_NOT_FOUND' :
				errorMessage = 'Formulaire non trouvé';
				break;
			default :
				break;
		}
		return errorMessage;
	}
}
