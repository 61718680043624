import * as moment from 'moment';

import {FeuilleHeuresItemObject} from './feuille-heures-item.object';

export class FeuilleHeuresJourObject {
	id: number;
	table = 'feuille_heure_jour';
	titre = 'fh jour';
	createdAt: string;
	updatedAt: string;
	semaine_id: number;
	date: string;
	panier: boolean;
	nuite: boolean;
	type_deplacement: string;
	montant_frais_reels: number;
	heures_route: number;
	remarques: string;
	rtt_paye: boolean;
	regularisation_hs_majorees: number;
	regularisation_hs_non_majorees: number;
	reliquat: number;
	items: Array<FeuilleHeuresItemObject>;

	constructor(id = null, createdAt = null, updatedAt = null, semaine_id = null, date = null, panier = null, nuite = null, type_deplacement = null, montant_frais_reels = null, heures_route = null, remarques = null,
				rtt_paye = null, regularisation_hs_majorees = null, regularisation_hs_non_majorees = null, reliquat = null, items = []) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.semaine_id = semaine_id;
		this.date = date;
		this.panier = panier;
		this.nuite = nuite;
		this.type_deplacement = type_deplacement;
		this.montant_frais_reels = montant_frais_reels;
		this.heures_route = heures_route;
		this.remarques = remarques;
		this.rtt_paye = rtt_paye;
		this.regularisation_hs_majorees = regularisation_hs_majorees;
		this.regularisation_hs_non_majorees = regularisation_hs_non_majorees;
		this.reliquat = reliquat;
		this.items = items;
	}

	public static LoadFromJSON(json: any) {
		return new FeuilleHeuresJourObject(
			json['id'],
			json['createdAt'],
			json['updatedAt'],
			json['semaine_id'],
			json['date'],
			json['panier'],
			json['nuite'],
			json['type_deplacement'],
			json['montant_frais_reels'],
			json['heures_route'],
			json['remarques'],
			json['rtt_paye'],
			json['regularisation_hs_majorees'],
			json['regularisation_hs_non_majorees'],
			json['reliquat'],
			this.LoadFromArray(json['items'])
		);
	}

	public static LoadFromArray(arr: any[]) {
		const ret = [];
		if (arr) {
			for (let i = 0; i < arr.length; i++) {
				ret.push(FeuilleHeuresItemObject.LoadFromJSON(arr[i]));
			}
		}
		return ret;
	}

	missingMandatoryFields() {
		if (this.heures_route !== null) {
			if (this.heures_route.toString() !== '' && !/^\d+(\.(25|5|75|0)0*)?$/.test(this.heures_route.toString())) {
				return 'Le format des heures de route doit être .0, .25, .5 ou .75.';
			}
		}
		const day = moment(this.date).format('dddd DD/MM/YYYY');
		for (const i in this.items) {
			const missingFieldMessage = this.items[i].missingMandatoryFields(day);
			if (missingFieldMessage) {
				return missingFieldMessage;
			}
		}
		return null;
	}

	clearRelatedFields(field) {
		switch (field) {
			case 'type_deplacement':
				if (this.type_deplacement !== 'Frais réels') {
					this.montant_frais_reels = null;
				}
				break;
			default :
				break;
		}
	}

	getTempsPasse() {
		let tempsPasse = 0;
		for (const i in this.items) {
			if (this.items[i].temps_travail !== null) {
				tempsPasse += this.items[i].temps_travail;
			}
		}
		return tempsPasse;
	}

	getFormData() {
		return {
			semaine_id: this.semaine_id,
			date: this.date ? moment(this.date).format('YYYY-MM-DD') : null,
			panier: this.panier,
			nuite: this.nuite,
			type_deplacement: this.type_deplacement,
			montant_frais_reels: this.montant_frais_reels,
			heures_route: this.heures_route,
			remarques: this.remarques,
			rtt_paye: this.rtt_paye,
			regularisation_hs_majorees: this.regularisation_hs_majorees,
			regularisation_hs_non_majorees: this.regularisation_hs_non_majorees,
			reliquat: this.reliquat
		};
	}
}
