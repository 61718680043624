import {Injectable, isDevMode} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {Network} from '@capacitor/network';

import * as moment from 'moment';

import {WebService} from './web.service';
import {UsersService} from './users.service';
import {PdfService} from './pdf.service';
import {LocalDataService} from './local-data.service';
import {FileService} from './file.service';
import {IndicatorsService} from './indicators.service';

// Sécurité

import {AccueilSecuriteObject} from '../objects/forms/securite/accueil-securite.object';
import {PlanPreventionObject} from '../objects/forms/securite/plan-prevention.object';
import {AnalyseRisquesObject} from '../objects/forms/securite/analyse-risques.object';
import {InspectionChantierObject} from '../objects/forms/securite/inspection-chantier.object';
import {RapportIncidentObject} from '../objects/forms/securite/rapport-incident.object';
import {ConsigneHarnaisObject} from '../objects/forms/securite/consigne-harnais.object';

// Production

import {OuvertureAffaireObject} from '../objects/forms/production/ouverture-affaire.object';
import {DemandeTravauxObject} from '../objects/forms/production/demande-travaux.object';
import {CrInterventionObject} from '../objects/forms/production/cr-intervention.object';
import {PvReceptionObject} from '../objects/forms/production/pv-reception.object';
import {FicheQualiteObject} from '../objects/forms/production/fiche-qualite.object';

// Administratif

import {DemandeAbsenceObject} from '../objects/forms/administratif/demande-absence.object';
import {FeuilleHeuresSemaineObject} from '../objects/forms/administratif/feuille-heures-semaine.object';

// Commerce

import {DevisObject} from '../objects/forms/commerce/devis.object';
import {AffaireObject} from '../objects/forms/commerce/affaire.object';

// Planning

import {AffaireTacheObject} from '../objects/forms/commerce/affaire-tache.object';
import {AffaireTachePlanificationObject} from '../objects/forms/commerce/affaire-tache-planification.object';
import {PlanningTacheObject} from '../objects/forms/planning/planning-tache.object';
import {PlanningVehiculeObject} from '../objects/forms/planning/planning-vehicule.object';

// Parametres

import {VehiculeObject} from '../objects/forms/parametres/vehicule.object';

import {UserObject} from '../objects/user.object';

import {AppUtils} from '../app.utils';
import {AppConfig} from '../app.config';

@Injectable({
	providedIn: 'root'
})
export class FormsService {

	platformIsDesktop: boolean;

	constructor(private webService: WebService, private usersService: UsersService, private pdfService: PdfService, private localDataService: LocalDataService, private fileService: FileService, private platform: Platform, private modalCtrl: ModalController,
				private indicatorsService: IndicatorsService) {
		this.platformIsDesktop = (this.platform.is('desktop') && !this.platform.is('phablet')) || (this.platform.is('tablet') && this.platform.is('mobileweb')) || (this.platform.is('mobile') && this.platform.is('mobileweb'));
	}

	async getForms(form, pagination, order, filters = null, groups = null) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				throw 'NO_NETWORK';
			} else {
				const conf = {
					param_1: 'api',
					param_2: 'auth',
					param_3: 'form',
					param_4: form,
					params: {
						order: order.orderByField,
						orderdirection: order.orderByDirection
					}
				};
				if (pagination.bypages) {
					conf.params['bypages'] = pagination.bypages;
				}
				if (pagination.page) {
					conf.params['page'] = pagination.page;
				}
				if (groups !== null) {
					for (const i in groups) {
						conf.params['groups[' + i + ']'] = groups[i];
					}
				}
				for (const i in filters) {
					conf.params[filters[i].name] = filters[i].value;
				}
				const result: any = await this.webService.get(conf);
				const forms = [];
				switch (form) {
					case 'devis':
						for (const i in result.data?.items) {
							forms.push(DevisObject.LoadFromJSON(result.data.items[i]));
						}
						break;
					case 'affaire':
						for (const i in result.data?.items) {
							forms.push(AffaireObject.LoadFromJSON(result.data.items[i]));
						}
						break;
					case 'vehicule':
						for (const i in result.data?.items) {
							forms.push(VehiculeObject.LoadFromJSON(result.data.items[i]));
						}
						break;
					case 'affaire_tache':
						for (const i in result.data?.items) {
							forms.push(AffaireTacheObject.LoadFromJSON(result.data.items[i]));
						}
						break;
					case 'affaire_tache_planification':
						for (const i in result.data?.items) {
							forms.push(AffaireTachePlanificationObject.LoadFromJSON(result.data.items[i]));
						}
						break;
					case 'demande_absence':
						for (const i in result.data?.items) {
							forms.push(DemandeAbsenceObject.LoadFromJSON(result.data.items[i]));
						}
						break;
					case 'planning_tache':
						for (const i in result.data?.items) {
							forms.push(PlanningTacheObject.LoadFromJSON(result.data.items[i]));
						}
						break;
					case 'planning_vehicule':
						for (const i in result.data?.items) {
							forms.push(PlanningVehiculeObject.LoadFromJSON(result.data.items[i]));
						}
						break;
					case 'feuille_heure_semaine':
						for (const i in result.data?.items) {
							forms.push(FeuilleHeuresSemaineObject.LoadFromJSON(result.data.items[i]));
						}
						break;
				}
				return {
					forms: forms,
					pages: result.data?.pages,
					total: result.data?.total
				};
			}
		} catch (error) {
			throw error;
		}
	}

	async getForm(form, id, groups = null) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				throw 'NO_NETWORK';
			} else {
				const conf = {
					param_1: 'api',
					param_2: 'auth',
					param_3: 'form',
					param_4: form,
					param_5: id,
					params: {}
				};
				if (groups !== null) {
					for (const i in groups) {
						conf.params['groups[' + i + ']'] = groups[i];
					}
				}
				const result: any = await this.webService.get(conf);
				if (result.data) {
					switch (form) {
						case 'devis':
							return DevisObject.LoadFromJSON(result.data);
						case 'affaire':
							return AffaireObject.LoadFromJSON(result.data);
						case 'affaire_tache':
							return AffaireTacheObject.LoadFromJSON(result.data);
						case 'affaire_tache_planification':
							return AffaireTachePlanificationObject.LoadFromJSON(result.data);
						case 'planning_tache':
							return PlanningTacheObject.LoadFromJSON(result.data);
						case 'planning_vehicule':
							return PlanningVehiculeObject.LoadFromJSON(result.data);
						case 'vehicule':
							return VehiculeObject.LoadFromJSON(result.data);
						case 'feuille_heure_semaine':
							return FeuilleHeuresSemaineObject.LoadFromJSON(result.data);
					}
				} else {
					throw 'FORM_NOT_FOUND';
				}
			}
		} catch (error) {
			throw error;
		}
	}

	async getAllFormsOld(fromDate, toDate, userConnected: UserObject) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				if (this.platformIsDesktop) {
					throw 'NO_NETWORK';
				} else {
					const formsSavedLocally = await this.localDataService.getItem('forms_saved_locally');
					if (formsSavedLocally) {
						return formsSavedLocally;
					} else {
						throw 'NO_NETWORK';
					}
				}
			} else {
				const promises = [];
				for (const i in AppConfig.forms) {
					const conf = {
						param_1: 'api',
						param_2: 'auth',
						param_3: 'form',
						param_4: AppConfig.forms[i].table,
						params: {
							group: 'custom_all',
							bypages: AppConfig.nbItemsPerPage,
						}
					};
					conf.params[AppConfig.forms[i].table + '.updatedAt[between]'] = moment(fromDate).format('YYYY-MM-DD') + ',' + moment(toDate).format('YYYY-MM-DD');
					promises.push(this.webService.get(conf));
				}
				const result = await Promise.all(promises);
				const formsGrouped = [];
				for (const i in result) {
					for (const j in result[i].data.items) {
						result[i].data.items[j].titre = AppConfig.forms[i].titre;
						result[i].data.items[j].table = AppConfig.forms[i].table;
						if (result[i].data.items[j].createdBy) {
							result[i].data.items[j].createdBy = UserObject.LoadFromJSON(result[i].data.items[j].createdBy);
						}
						if (result[i].data.items[j].updatedBy) {
							result[i].data.items[j].updatedBy = UserObject.LoadFromJSON(result[i].data.items[j].updatedBy);
						}
						if (userConnected != null && userConnected.isAuthorized(result[i].data.items[j].table, 'Visualiser', result[i].data.items[j].status, result[i].data.items[j].createdBy?.id)) {
							formsGrouped.push(result[i].data.items[j]);
						}
					}
				}
				if (this.platformIsDesktop) {
					return formsGrouped;
				} else {
					const formsSavedLocally = await this.localDataService.getItem('forms_saved_locally');
					if (formsSavedLocally) {
						const formsSavedLocallyToDelete = [];
						for (const i in formsSavedLocally) {
							let savedLocallyFound = false;
							for (const j in formsGrouped) {
								if (formsSavedLocally[i].id === formsGrouped[j].id && formsSavedLocally[i].table === formsGrouped[j].table) {
									savedLocallyFound = true;
									if (moment(formsSavedLocally[i].updatedAt).format() > moment(formsGrouped[j].updatedAt).format()) {
										formsGrouped[j] = formsSavedLocally[i];
									} else {
										formsSavedLocallyToDelete.push(formsSavedLocally[i]);
									}
									break;
								}
							}
							if (!savedLocallyFound) {
								formsGrouped.push(formsSavedLocally[i]);
							}
						}
						await this.deleteFormsSavedLocally(formsSavedLocallyToDelete);
						return formsGrouped;
					} else {
						return formsGrouped;
					}
				}
			}
		} catch (error) {
			throw error;
		}
	}

	async getFormsOld(table, filters, users) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				throw 'NO_NETWORK';
			} else {
				const conf = {
					param_1: 'api',
					param_2: 'auth',
					param_3: 'form',
					param_4: table,
					params: {
						group: 'custom_all',
						bypages: AppConfig.nbItemsPerPage,
					}
				};
				for (const i in filters) {
					conf.params[filters[i].name] = filters[i].value;
				}
				const result: any = await this.webService.get(conf);
				const promises = [];
				for (const i in result?.data?.items) {
					promises.push(this.getFormOld({table: table, id: result.data.items[i].id}, users));
				}
				return await Promise.all(promises);
			}
		} catch (error) {
			throw error;
		}
	}

	async saveForm(form) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				if (this.platformIsDesktop) {
					throw 'NO_NETWORK';
				} else {
					return await this.saveFormLocally(form);
				}
			} else {
				const formData = form.getFormData();

				let conf;
				let result;

				if (form.id === null || form.id < 0) {
					conf = {
						param_1: 'api',
						param_2: 'auth',
						param_3: 'form',
						param_4: form.table,
						formData: formData
					};

					result = await this.webService.post(conf);
				} else {
					conf = {
						param_1: 'api',
						param_2: 'auth',
						param_3: 'form',
						param_4: form.table,
						param_5: form.id,
						formData: formData
					};

					result = await this.webService.post(conf);
				}

				if (!this.platformIsDesktop) {
					await this.deleteFormsSavedLocally([form]);
				}
				return result.data;
			}
		} catch (error) {
			throw error;
		}
	}

	async saveFormLocally(form) {
		if (form.id == null) {
			let tmp_id: number = await this.localDataService.getItem('current_tmp_id');
			if (tmp_id == null) {
				tmp_id = -1;
			} else {
				tmp_id--;
			}
			await this.localDataService.setItem('current_tmp_id', tmp_id);
			form.id = tmp_id;
		}
		let formsSavedLocally: any = await this.localDataService.getItem('forms_saved_locally');
		let formFound = false;
		if (formsSavedLocally == null) {
			formsSavedLocally = [];
		}
		for (const i in formsSavedLocally) {
			if (formsSavedLocally[i].id === form.id) {
				formFound = true;
				formsSavedLocally[i] = {
					id: form.id,
					table: form.table,
					titre: form.titre,
					id_affaire: form.id_affaire,
					createdBy: form.createdBy,
					updatedAt: moment().format(),
					status: form.status,
					savedLocally: true,
					affaire: {nom_client: form.nom_client, nom: form.nom}
				};
				break;
			}
		}
		if (!formFound) {
			formsSavedLocally.push({
				id: form.id, table: form.table, titre: form.titre, id_affaire: form.id_affaire, createdBy: form.createdBy, updatedAt: moment().format(),
				status: form.status, savedLocally: true, affaire: {nom_client: form.nom_client, nom: form.nom}
			});
		}
		await this.localDataService.setItem('forms_saved_locally', formsSavedLocally);
		await this.localDataService.setItem(form.table + '_' + form.id, form);
		return {success: 1, id: form.id};
	}

	async getSignaturesSavedLocally(form) {
		const savedSignatures = await this.localDataService.getItem('signatures_' + form.table + '_' + form.id);
		return savedSignatures ? savedSignatures : [];
	}

	async saveSignatureLocally(form, signatures) {
		let removeSignatures = true;
		for (const i in signatures) {
			if (signatures[i]) {
				removeSignatures = false;
				break;
			}
		}
		if (removeSignatures) {
			await this.localDataService.removeItem('signatures_' + form.table + '_' + form.id);
		} else {
			await this.localDataService.setItem('signatures_' + form.table + '_' + form.id, signatures);
		}
	}

	async deleteFormsSavedLocally(forms: Array<any>) {
		const formsSavedLocally: any = await this.localDataService.getItem('forms_saved_locally');
		const newFormsSavedLocally = [];
		for (const i in formsSavedLocally) {
			let found = false;
			for (const j in forms) {
				if (formsSavedLocally[i].id === forms[j].id) {
					found = true;
				}
			}
			if (!found) {
				newFormsSavedLocally.push(formsSavedLocally[i]);
			}
		}
		await this.localDataService.setItem('forms_saved_locally', newFormsSavedLocally);
		const promises = [];
		for (const i in forms) {
			promises.push(this.localDataService.removeItem(forms[i].table + '_' + forms[i].id));
		}
		await Promise.all(promises);
	}

	async getFormOld(form, users: Array<UserObject>) {
		try {
			const networkStatus = await Network.getStatus();
			if ((!networkStatus.connected && !this.platformIsDesktop) || form.savedLocally) {
				const formSavedLocally: any = await this.localDataService.getItem(form.table + '_' + form.id);
				if (formSavedLocally == null) {
					throw 'NO_NETWORK';
				} else {
					let tmpForm = null;
					switch (form.table) {

						// Sécurite

						case 'accueil_securite' :
							return new AccueilSecuriteObject(
								formSavedLocally.id,
								formSavedLocally.createdAt,
								formSavedLocally.updatedAt,
								formSavedLocally.status,
								formSavedLocally.createdBy,
								formSavedLocally.updatedBy,

								// Step 1

								formSavedLocally.id_affaire,

								// Step 2

								form.affaire != null ? form.affaire.nom_client : null,
								form.affaire != null ? form.affaire.code_client : null,
								form.affaire != null ? form.affaire.nom : null,
								form.affaire != null ? form.affaire.responsable : null,

								// Step 3

								formSavedLocally.aptitude_medicale,
								formSavedLocally.aptitude_medicale_description,
								formSavedLocally.autorisation_acces_chantier,
								formSavedLocally.autorisation_acces_chantier_description,
								formSavedLocally.titre_habilitation_electrique,
								formSavedLocally.titre_habilitation_electrique_description,
								formSavedLocally.titre_formation_chimique,
								formSavedLocally.titre_formation_chimique_description,
								formSavedLocally.cerfa_n1138302,
								formSavedLocally.cerfa_n1138302_description,
								formSavedLocally.autorisation_conduite_nacelle,
								formSavedLocally.autorisation_conduite_nacelle_description,
								formSavedLocally.caces_nacelle,
								formSavedLocally.caces_nacelle_description,
								formSavedLocally.autorisation_echaffaudage,
								formSavedLocally.autorisation_echaffaudage_description,
								formSavedLocally.casque_vetements_travail_chaussures_securite,
								formSavedLocally.casque_vetements_travail_chaussures_securite_description,
								formSavedLocally.vat_casque_idra,
								formSavedLocally.vat_casque_idra_description,
								formSavedLocally.outillage_individuel,
								formSavedLocally.outillage_individuel_description,
								formSavedLocally.travail_cours_effectuer,
								formSavedLocally.presence_signature_pdp_ppsps,
								formSavedLocally.explication_visite_chantier_realises,
								formSavedLocally.droit_alerte_retrait,

								// Step 4

								formSavedLocally.risques_electriques,
								formSavedLocally.risques_electriques_details_basse_tension,
								formSavedLocally.risques_electriques_details_haute_tension,
								formSavedLocally.risques_electriques_details_voisinage_tension,
								formSavedLocally.risques_electriques_details_energie_permanente,
								formSavedLocally.risques_electriques_prevention_habilitation,
								formSavedLocally.risques_electriques_prevention_consignation,
								formSavedLocally.risques_electriques_prevention_voisinage,
								formSavedLocally.risques_lies_circulation,
								formSavedLocally.risques_lies_circulation_details_vehicules,
								formSavedLocally.risques_lies_circulation_details_pietonne,
								formSavedLocally.risques_lies_circulation_moyens_prevention_regles,
								formSavedLocally.risques_lies_circulation_moyens_prevention_baliser,
								formSavedLocally.risques_lies_circulation_moyens_prevention_epi,
								formSavedLocally.risques_chute_hauteur,
								formSavedLocally.risques_chute_hauteur_details_nacelle,
								formSavedLocally.risques_chute_hauteur_details_toiture,
								formSavedLocally.risques_chute_hauteur_details_echafaudage,
								formSavedLocally.risques_chute_hauteur_details_echelle,
								formSavedLocally.risques_chute_hauteur_details_harnais,
								formSavedLocally.risques_chute_hauteur_prevention_materiel,
								formSavedLocally.risques_chute_hauteur_prevention_conduite,
								formSavedLocally.risques_chute_hauteur_prevention_balisage,
								formSavedLocally.risques_chute_hauteur_prevention_harnais,
								formSavedLocally.risques_chute_hauteur_prevention_permis,
								formSavedLocally.risques_chute_charge_objet,
								formSavedLocally.risques_chute_charge_objet_prevention_casque,
								formSavedLocally.risques_chute_charge_objet_prevention_baliser,
								formSavedLocally.risques_mecaniques,
								formSavedLocally.risques_mecaniques_details_manipulation,
								formSavedLocally.risques_mecaniques_details_mecanisme,
								formSavedLocally.risques_mecaniques_details_electro,
								formSavedLocally.risques_mecaniques_prevention_gants,
								formSavedLocally.risques_mecaniques_prevention_etat,
								formSavedLocally.risques_mecaniques_prevention_balisage,
								formSavedLocally.risques_mecaniques_prevention_permis,
								formSavedLocally.risques_mecaniques_prevention_mecaniques,
								formSavedLocally.risques_chimiques,
								formSavedLocally.risques_chimiques_details_manipulation,
								formSavedLocally.risques_chimiques_details_voisinage,
								formSavedLocally.risques_chimiques_prevention_epi,
								formSavedLocally.risques_chimiques_prevention_produit,
								formSavedLocally.risques_chimiques_prevention_epi_client,
								formSavedLocally.risques_incendie,
								formSavedLocally.risques_incendie_details_soudure,
								formSavedLocally.risques_incendie_details_produits,
								formSavedLocally.risques_incendie_prevention_consignes,
								formSavedLocally.risques_incendie_prevention_fumer,
								formSavedLocally.risques_incendie_prevention_atex,
								formSavedLocally.risques_incendie_prevention_permis,
								formSavedLocally.risques_incendie_prevention_extincteurs,
								formSavedLocally.risques_nuisances,
								formSavedLocally.risques_nuisances_details_poussieres,
								formSavedLocally.risques_nuisances_details_bruit,
								formSavedLocally.risques_nuisances_prevention_auditives,
								formSavedLocally.risques_nuisances_prevention_masque,
								formSavedLocally.risques_nuisances_prevention_lunettes,
								formSavedLocally.risques_nuisances_prevention_zone,
								formSavedLocally.risques_lies_coactivite,
								formSavedLocally.risques_lies_coactivite_details_entreprises,
								formSavedLocally.risques_lies_coactivite_prevention_nature,
								formSavedLocally.risques_lies_coactivite_prevention_precautions,
								formSavedLocally.risques_lies_coactivite_prevention_communication,
								formSavedLocally.risques_zone_alimentaire,
								formSavedLocally.risques_zone_alimentaire_prevention_regles,
								formSavedLocally.risques_zone_alimentaire_prevention_epi,
								formSavedLocally.autres_risques,
								formSavedLocally.autres_risques_type,
								formSavedLocally.autres_risques_prevention,

								// Step 5

								formSavedLocally.moyens_urgence_sortie,
								formSavedLocally.moyens_urgence_pharmacie,
								formSavedLocally.moyens_urgence_telephone,
								formSavedLocally.moyens_urgence_rassemblement,
								formSavedLocally.moyens_urgence_rincage,
								formSavedLocally.moyens_urgence_douche,
								formSavedLocally.moyens_urgence_extincteur,

								// Step 6

								formSavedLocally.date_signature,
								formSavedLocally.signatories,
								formSavedLocally.signatories_dates,
								formSavedLocally.personnes_supplementaires
							);
						case 'plan_prevention' :
							return new PlanPreventionObject(
								formSavedLocally.id,
								formSavedLocally.createdAt,
								formSavedLocally.updatedAt,
								formSavedLocally.status,
								formSavedLocally.createdBy,
								formSavedLocally.updatedBy,

								// Step 1

								formSavedLocally.id_affaire,

								// Step 2

								formSavedLocally.responsable.nom_client,
								form.affaire != null ? form.affaire.nom : null,
								formSavedLocally.interlocuteur,
								formSavedLocally.telephone,
								formSavedLocally.entreprise_exterieure,
								formSavedLocally.entreprise_exterieure_responsable,
								formSavedLocally.entreprise_exterieure_telephone,
								formSavedLocally.responsable_travaux,
								formSavedLocally.nb_stemi,
								formSavedLocally.lieu,
								formSavedLocally.date_debut_travaux,
								formSavedLocally.date_fin_travaux,
								formSavedLocally.horaires_intervention,
								formSavedLocally.presence_amiante,
								formSavedLocally.localisation_amiante,

								// Step 3

								formSavedLocally.epi_chaussures_securite,
								formSavedLocally.epi_lunettes_protection,
								formSavedLocally.epi_protection_auditive,
								formSavedLocally.epi_casque_chantier,
								formSavedLocally.epi_vetements_travail,
								formSavedLocally.epi_gilet,

								// Step 4

								formSavedLocally.moyens_urgence_sortie,
								formSavedLocally.moyens_urgence_sortie_commentaire,
								formSavedLocally.moyens_urgence_pharmacie,
								formSavedLocally.moyens_urgence_pharmacie_commentaire,
								formSavedLocally.moyens_urgence_telephone,
								formSavedLocally.moyens_urgence_telephone_commentaire,
								formSavedLocally.moyens_urgence_rassemblement,
								formSavedLocally.moyens_urgence_rassemblement_commentaire,
								formSavedLocally.moyens_urgence_rincage,
								formSavedLocally.moyens_urgence_rincage_commentaire,
								formSavedLocally.moyens_urgence_douche,
								formSavedLocally.moyens_urgence_douche_commentaire,
								formSavedLocally.moyens_urgence_extincteur,
								formSavedLocally.moyens_urgence_extincteur_commentaire,

								// Step 5

								formSavedLocally.prestataire,
								formSavedLocally.prestataire_societe,
								formSavedLocally.prestataire_responsable,
								formSavedLocally.prestataire_nature_travaux,
								formSavedLocally.risques_electriques,
								formSavedLocally.risques_electriques_details_basse_tension,
								formSavedLocally.risques_electriques_details_haute_tension,
								formSavedLocally.risques_electriques_details_voisinage_tension,
								formSavedLocally.risques_electriques_prevention_habilitation,
								formSavedLocally.risques_electriques_prevention_habilitation_par_ee,
								formSavedLocally.risques_electriques_prevention_habilitation_par_eu,
								formSavedLocally.risques_electriques_prevention_consignation,
								formSavedLocally.risques_electriques_prevention_consignation_par_ee,
								formSavedLocally.risques_electriques_prevention_consignation_par_eu,
								formSavedLocally.risques_electriques_prevention_voisinage,
								formSavedLocally.risques_electriques_prevention_voisinage_par_ee,
								formSavedLocally.risques_electriques_prevention_voisinage_par_eu,
								formSavedLocally.risques_electriques_remarques,
								formSavedLocally.risques_lies_circulation,
								formSavedLocally.risques_lies_circulation_details_vehicules,
								formSavedLocally.risques_lies_circulation_details_pietonne,
								formSavedLocally.risques_lies_circulation_moyens_prevention_regles,
								formSavedLocally.risques_lies_circulation_moyens_prevention_regles_par_ee,
								formSavedLocally.risques_lies_circulation_moyens_prevention_regles_par_eu,
								formSavedLocally.risques_lies_circulation_moyens_prevention_baliser,
								formSavedLocally.risques_lies_circulation_moyens_prevention_baliser_par_ee,
								formSavedLocally.risques_lies_circulation_moyens_prevention_baliser_par_eu,
								formSavedLocally.risques_lies_circulation_moyens_prevention_epi,
								formSavedLocally.risques_lies_circulation_moyens_prevention_epi_par_ee,
								formSavedLocally.risques_lies_circulation_moyens_prevention_epi_par_eu,
								formSavedLocally.risques_lies_circulation_remarques,
								formSavedLocally.risques_chute_hauteur,
								formSavedLocally.risques_chute_hauteur_details_nacelle,
								formSavedLocally.risques_chute_hauteur_details_echafaudage,
								formSavedLocally.risques_chute_hauteur_details_echelle,
								formSavedLocally.risques_chute_hauteur_prevention_materiel,
								formSavedLocally.risques_chute_hauteur_prevention_materiel_par_ee,
								formSavedLocally.risques_chute_hauteur_prevention_materiel_par_eu,
								formSavedLocally.risques_chute_hauteur_prevention_conduite,
								formSavedLocally.risques_chute_hauteur_prevention_conduite_par_ee,
								formSavedLocally.risques_chute_hauteur_prevention_conduite_par_eu,
								formSavedLocally.risques_chute_hauteur_prevention_balisage,
								formSavedLocally.risques_chute_hauteur_prevention_balisage_par_ee,
								formSavedLocally.risques_chute_hauteur_prevention_balisage_par_eu,
								formSavedLocally.risques_chute_hauteur_prevention_harnais,
								formSavedLocally.risques_chute_hauteur_prevention_harnais_par_ee,
								formSavedLocally.risques_chute_hauteur_prevention_harnais_par_eu,
								formSavedLocally.risques_chute_hauteur_prevention_permis,
								formSavedLocally.risques_chute_hauteur_prevention_permis_par_ee,
								formSavedLocally.risques_chute_hauteur_prevention_permis_par_eu,
								formSavedLocally.risques_chute_hauteur_prevention_pret_materiel,
								formSavedLocally.risques_chute_hauteur_remarques,
								formSavedLocally.risques_chute_charge_objet,
								formSavedLocally.risques_chute_charge_objet_prevention_casque,
								formSavedLocally.risques_chute_charge_objet_prevention_casque_par_ee,
								formSavedLocally.risques_chute_charge_objet_prevention_casque_par_eu,
								formSavedLocally.risques_chute_charge_objet_prevention_baliser,
								formSavedLocally.risques_chute_charge_objet_prevention_baliser_par_ee,
								formSavedLocally.risques_chute_charge_objet_prevention_baliser_par_eu,
								formSavedLocally.risques_chute_charge_objet_remarques,
								formSavedLocally.risques_mecaniques,
								formSavedLocally.risques_mecaniques_details_mecanisme,
								formSavedLocally.risques_mecaniques_details_electro,
								formSavedLocally.risques_mecaniques_prevention_gants,
								formSavedLocally.risques_mecaniques_prevention_gants_par_ee,
								formSavedLocally.risques_mecaniques_prevention_gants_par_eu,
								formSavedLocally.risques_mecaniques_prevention_etat,
								formSavedLocally.risques_mecaniques_prevention_etat_par_ee,
								formSavedLocally.risques_mecaniques_prevention_etat_par_eu,
								formSavedLocally.risques_mecaniques_prevention_balisage,
								formSavedLocally.risques_mecaniques_prevention_balisage_par_ee,
								formSavedLocally.risques_mecaniques_prevention_balisage_par_eu,
								formSavedLocally.risques_mecaniques_prevention_permis,
								formSavedLocally.risques_mecaniques_prevention_permis_par_ee,
								formSavedLocally.risques_mecaniques_prevention_permis_par_eu,
								formSavedLocally.risques_mecaniques_remarques,
								formSavedLocally.risques_chimiques,
								formSavedLocally.risques_chimiques_details_manipulation,
								formSavedLocally.risques_chimiques_details_voisinage,
								formSavedLocally.risques_chimiques_prevention_epi,
								formSavedLocally.risques_chimiques_prevention_epi_par_ee,
								formSavedLocally.risques_chimiques_prevention_epi_par_eu,
								formSavedLocally.risques_chimiques_prevention_produit,
								formSavedLocally.risques_chimiques_prevention_produit_par_ee,
								formSavedLocally.risques_chimiques_prevention_produit_par_eu,
								formSavedLocally.risques_chimiques_remarques,
								formSavedLocally.risques_incendie,
								formSavedLocally.risques_incendie_details_soudure,
								formSavedLocally.risques_incendie_details_produits,
								formSavedLocally.risques_incendie_prevention_consignes,
								formSavedLocally.risques_incendie_prevention_consignes_par_ee,
								formSavedLocally.risques_incendie_prevention_consignes_par_eu,
								formSavedLocally.risques_incendie_prevention_fumer,
								formSavedLocally.risques_incendie_prevention_fumer_par_ee,
								formSavedLocally.risques_incendie_prevention_fumer_par_eu,
								formSavedLocally.risques_incendie_prevention_atex,
								formSavedLocally.risques_incendie_prevention_atex_par_ee,
								formSavedLocally.risques_incendie_prevention_atex_par_eu,
								formSavedLocally.risques_incendie_prevention_permis,
								formSavedLocally.risques_incendie_prevention_permis_par_ee,
								formSavedLocally.risques_incendie_prevention_permis_par_eu,
								formSavedLocally.risques_incendie_prevention_extincteurs,
								formSavedLocally.risques_incendie_prevention_extincteurs_par_ee,
								formSavedLocally.risques_incendie_prevention_extincteurs_par_eu,
								formSavedLocally.risques_incendie_remarques,
								formSavedLocally.risques_nuisances,
								formSavedLocally.risques_nuisances_details_poussieres,
								formSavedLocally.risques_nuisances_details_bruit,
								formSavedLocally.risques_nuisances_prevention_auditives,
								formSavedLocally.risques_nuisances_prevention_auditives_par_ee,
								formSavedLocally.risques_nuisances_prevention_auditives_par_eu,
								formSavedLocally.risques_nuisances_prevention_masque,
								formSavedLocally.risques_nuisances_prevention_masque_par_ee,
								formSavedLocally.risques_nuisances_prevention_masque_par_eu,
								formSavedLocally.risques_nuisances_prevention_lunettes,
								formSavedLocally.risques_nuisances_prevention_lunettes_par_ee,
								formSavedLocally.risques_nuisances_prevention_lunettes_par_eu,
								formSavedLocally.risques_nuisances_remarques,
								formSavedLocally.risques_lies_coactivite,
								formSavedLocally.risques_lies_coactivite_details_entreprises,
								formSavedLocally.risques_lies_coactivite_prevention_nature,
								formSavedLocally.risques_lies_coactivite_prevention_precautions,
								formSavedLocally.risques_lies_coactivite_prevention_precautions_par_ee,
								formSavedLocally.risques_lies_coactivite_prevention_precautions_par_eu,
								formSavedLocally.risques_lies_coactivite_remarques,
								formSavedLocally.risques_bacteriologiques,
								formSavedLocally.risques_bacteriologiques_details_zone_blanche,
								formSavedLocally.risques_bacteriologiques_prevention_regles_hygiene,
								formSavedLocally.risques_bacteriologiques_prevention_regles_hygiene_par_ee,
								formSavedLocally.risques_bacteriologiques_prevention_regles_hygiene_par_eu,
								formSavedLocally.risques_bacteriologiques_prevention_port_epi,
								formSavedLocally.risques_bacteriologiques_prevention_port_epi_par_ee,
								formSavedLocally.risques_bacteriologiques_prevention_port_epi_par_eu,
								formSavedLocally.risques_bacteriologiques_remarques,
								formSavedLocally.autres_risques,
								formSavedLocally.autres_risques_type,
								formSavedLocally.autres_risques_prevention,
								formSavedLocally.autres_risques_prevention_par_ee,
								formSavedLocally.autres_risques_prevention_par_eu,
								formSavedLocally.autres_risques_remarques,
								formSavedLocally.document_eu_plan_site,
								formSavedLocally.document_eu_autres,
								formSavedLocally.document_eu_autres_description,

								// Step 6

								formSavedLocally.date_signature,
								formSavedLocally.client_nom_signature,
								formSavedLocally.client_date_signature,
								formSavedLocally.client_signature
							);
						case 'analyse_risques' :
							return new AnalyseRisquesObject(
								formSavedLocally.id,
								formSavedLocally.createdAt,
								formSavedLocally.updatedAt,
								formSavedLocally.status,
								formSavedLocally.createdBy,
								formSavedLocally.updatedBy,

								// Step 1

								formSavedLocally.id_affaire,

								// Step 2

								form.affaire != null ? form.affaire.nom_client : null,
								form.affaire != null ? form.affaire.code_client : null,
								form.affaire != null ? form.affaire.contact : null,
								form.affaire != null ? form.affaire.nom : null,
								form.affaire != null ? form.affaire.charge_affaire : null,
								formSavedLocally.chef_chantier,
								form.affaire != null ? form.affaire.no_devis : null,

								// Step 3

								formSavedLocally.risques_presents_electriques,
								formSavedLocally.risques_presents_coupure,
								formSavedLocally.risques_presents_mecanique,
								formSavedLocally.risques_presents_manuelle,
								formSavedLocally.risques_presents_ecrasement,
								formSavedLocally.risques_presents_projection,
								formSavedLocally.risques_presents_incendie,
								formSavedLocally.risques_presents_brulure,
								formSavedLocally.risques_presents_bruit,
								formSavedLocally.risques_presents_chute_plain_pied,
								formSavedLocally.risques_presents_chute_hauteur,
								formSavedLocally.risques_presents_coactivite,
								formSavedLocally.risques_presents_circulation,
								formSavedLocally.risques_presents_trajet,
								formSavedLocally.risques_presents_engin,
								formSavedLocally.risques_presents_chimiques,
								formSavedLocally.risques_presents_thermique,
								formSavedLocally.risques_presents_chutes_objets,
								formSavedLocally.risques_presents_agroalimentaire,
								formSavedLocally.autres_risques,
								formSavedLocally.autres_risques_consequences,
								formSavedLocally.autres_risques_prevention,
								formSavedLocally.specificites_chantier,

								// Step 4

								formSavedLocally.date_signature,
								formSavedLocally.ids_destinataires
							);
						case 'inspection_chantier' :
							tmpForm = new InspectionChantierObject(
								formSavedLocally.id,
								formSavedLocally.createdAt,
								formSavedLocally.updatedAt,
								formSavedLocally.status,
								formSavedLocally.createdBy,
								formSavedLocally.updatedBy,

								// Step 1

								formSavedLocally.id_affaire,

								// Step 2

								form.affaire != null ? form.affaire.nom_client : null,
								form.affaire != null ? form.affaire.code_client : null,
								form.affaire != null ? form.affaire.nom : null,
								form.affaire != null ? form.affaire.responsable : null,
								formSavedLocally.nature_travaux,
								formSavedLocally.nb_stemi,
								formSavedLocally.nb_interim,
								formSavedLocally.personne_stemi_auditee,
								formSavedLocally.personne_interim_auditee,
								formSavedLocally.personnes_supplementaires,

								// Step 3

								formSavedLocally.voie_acces,
								formSavedLocally.voie_acces_precision,
								formSavedLocally.chantier_signalise,
								formSavedLocally.chantier_signalise_precision,
								formSavedLocally.sortie_secours_libre,
								formSavedLocally.sortie_secours_libre_precision,
								formSavedLocally.chantier_ordre,
								formSavedLocally.chantier_ordre_precision,
								formSavedLocally.dta,
								formSavedLocally.dta_precision,
								formSavedLocally.autorisation_permis_valide,
								formSavedLocally.autorisation_permis_valide_precision,
								formSavedLocally.manoeuvre_consignation,
								formSavedLocally.manoeuvre_consignation_precision,
								formSavedLocally.permis_feu_extincteur,
								formSavedLocally.permis_feu_extincteur_precision,
								formSavedLocally.permis_penetrer,
								formSavedLocally.permis_penetrer_precision,
								formSavedLocally.personnel_stemi_identifie,
								formSavedLocally.personnel_stemi_identifie_precision,
								formSavedLocally.personnel_interim_identifie,
								formSavedLocally.personnel_interim_identifie_precision,
								formSavedLocally.accueils_hse,
								formSavedLocally.accueils_hse_precision,
								formSavedLocally.plan_prevention,
								formSavedLocally.plan_prevention_precision,
								formSavedLocally.analyse_risques,
								formSavedLocally.analyse_risques_precision,
								formSavedLocally.risques_lies_chantier,
								formSavedLocally.risques_lies_chantier_precision,
								formSavedLocally.risques_specifiques,
								formSavedLocally.risques_specifiques_precision,
								formSavedLocally.habilitations_disponibles,
								formSavedLocally.habilitations_disponibles_precision,
								formSavedLocally.habilitations_valides,
								formSavedLocally.habilitations_valides_precision,
								formSavedLocally.sst_present,
								formSavedLocally.sst_present_precision,
								formSavedLocally.echelle_etat,
								formSavedLocally.echelle_etat_precision,
								formSavedLocally.echafaudage_conforme,
								formSavedLocally.echafaudage_conforme_precision,
								formSavedLocally.fiches_autorisation,
								formSavedLocally.fiches_autorisation_precision,
								formSavedLocally.nacelle_conforme,
								formSavedLocally.nacelle_conforme_precision,
								formSavedLocally.etat_outillage,
								formSavedLocally.etat_outillage_precision,
								formSavedLocally.epi,
								formSavedLocally.epi_precision,
								formSavedLocally.epi_etat,
								formSavedLocally.epi_etat_precision,
								formSavedLocally.risques_coactivite,
								formSavedLocally.risques_coactivite_precision,
								formSavedLocally.vat_chantier,
								formSavedLocally.vat_chantier_precision,
								formSavedLocally.emplacement_douches,
								formSavedLocally.emplacement_douches_precision,
								formSavedLocally.moyens_alerte,
								formSavedLocally.moyens_alerte_precision,
								formSavedLocally.localisation_extincteurs,
								formSavedLocally.localisation_extincteurs_precision,
								formSavedLocally.harnais_controle,
								formSavedLocally.harnais_controle_precision,
								formSavedLocally.cadenas_consignation,
								formSavedLocally.cadenas_consignation_precision,
								formSavedLocally.etat_vestiaires,
								formSavedLocally.etat_vestiaires_precision,
								formSavedLocally.etat_sanitaires,
								formSavedLocally.etat_sanitaires_precision,
								formSavedLocally.tri_dechets,
								formSavedLocally.tri_dechets_precision,
								formSavedLocally.emplacement_bennes,
								formSavedLocally.emplacement_bennes_precision,
								formSavedLocally.actions_non_securitaires,
								formSavedLocally.actions_non_securitaires_precision,
								formSavedLocally.actions_securitaires,
								formSavedLocally.actions_securitaires_precision,
								formSavedLocally.situations_dangereuses,
								formSavedLocally.situations_dangereuses_precision,
								formSavedLocally.photos,

								// step 4

								formSavedLocally.action_voie_acces_description,
								formSavedLocally.action_voie_acces_responsable,
								formSavedLocally.action_voie_acces_application,
								formSavedLocally.action_chantier_signalise_description,
								formSavedLocally.action_chantier_signalise_responsable,
								formSavedLocally.action_chantier_signalise_application,
								formSavedLocally.action_sortie_secours_libre_description,
								formSavedLocally.action_sortie_secours_libre_responsable,
								formSavedLocally.action_sortie_secours_libre_application,
								formSavedLocally.action_chantier_ordre_description,
								formSavedLocally.action_chantier_ordre_responsable,
								formSavedLocally.action_chantier_ordre_application,
								formSavedLocally.action_dta_description,
								formSavedLocally.action_dta_responsable,
								formSavedLocally.action_dta_application,
								formSavedLocally.action_autorisation_permis_valide_description,
								formSavedLocally.action_autorisation_permis_valide_responsable,
								formSavedLocally.action_autorisation_permis_valide_application,
								formSavedLocally.action_manoeuvre_consignation_description,
								formSavedLocally.action_manoeuvre_consignation_responsable,
								formSavedLocally.action_manoeuvre_consignation_application,
								formSavedLocally.action_permis_feu_extincteur_description,
								formSavedLocally.action_permis_feu_extincteur_responsable,
								formSavedLocally.action_permis_feu_extincteur_application,
								formSavedLocally.action_permis_penetrer_description,
								formSavedLocally.action_permis_penetrer_responsable,
								formSavedLocally.action_permis_penetrer_application,
								formSavedLocally.action_personnel_stemi_identifie_description,
								formSavedLocally.action_personnel_stemi_identifie_responsable,
								formSavedLocally.action_personnel_stemi_identifie_application,
								formSavedLocally.action_personnel_interim_identifie_description,
								formSavedLocally.action_personnel_interim_identifie_responsable,
								formSavedLocally.action_personnel_interim_identifie_application,
								formSavedLocally.action_accueils_hse_description,
								formSavedLocally.action_accueils_hse_responsable,
								formSavedLocally.action_accueils_hse_application,
								formSavedLocally.action_plan_prevention_description,
								formSavedLocally.action_plan_prevention_responsable,
								formSavedLocally.action_plan_prevention_application,
								formSavedLocally.action_analyse_risques_description,
								formSavedLocally.action_analyse_risques_responsable,
								formSavedLocally.action_analyse_risques_application,
								formSavedLocally.action_risques_lies_chantier_description,
								formSavedLocally.action_risques_lies_chantier_responsable,
								formSavedLocally.action_risques_lies_chantier_application,
								formSavedLocally.action_risques_specifiques_description,
								formSavedLocally.action_risques_specifiques_responsable,
								formSavedLocally.action_risques_specifiques_application,
								formSavedLocally.action_habilitations_disponibles_description,
								formSavedLocally.action_habilitations_disponibles_responsable,
								formSavedLocally.action_habilitations_disponibles_application,
								formSavedLocally.action_habilitations_valides_description,
								formSavedLocally.action_habilitations_valides_responsable,
								formSavedLocally.action_habilitations_valides_application,
								formSavedLocally.action_sst_present_description,
								formSavedLocally.action_sst_present_responsable,
								formSavedLocally.action_sst_present_application,
								formSavedLocally.action_echelle_etat_description,
								formSavedLocally.action_echelle_etat_responsable,
								formSavedLocally.action_echelle_etat_application,
								formSavedLocally.action_echafaudage_conforme_description,
								formSavedLocally.action_echafaudage_conforme_responsable,
								formSavedLocally.action_echafaudage_conforme_application,
								formSavedLocally.action_fiches_autorisation_description,
								formSavedLocally.action_fiches_autorisation_responsable,
								formSavedLocally.action_fiches_autorisation_application,
								formSavedLocally.action_nacelle_conforme_description,
								formSavedLocally.action_nacelle_conforme_responsable,
								formSavedLocally.action_nacelle_conforme_application,
								formSavedLocally.action_etat_outillage_description,
								formSavedLocally.action_etat_outillage_responsable,
								formSavedLocally.action_etat_outillage_application,
								formSavedLocally.action_epi_description,
								formSavedLocally.action_epi_responsable,
								formSavedLocally.action_epi_application,
								formSavedLocally.action_epi_etat_description,
								formSavedLocally.action_epi_etat_responsable,
								formSavedLocally.action_epi_etat_application,
								formSavedLocally.action_risques_coactivite_description,
								formSavedLocally.action_risques_coactivite_responsable,
								formSavedLocally.action_risques_coactivite_application,
								formSavedLocally.action_vat_chantier_description,
								formSavedLocally.action_vat_chantier_responsable,
								formSavedLocally.action_vat_chantier_application,
								formSavedLocally.action_emplacement_douches_description,
								formSavedLocally.action_emplacement_douches_responsable,
								formSavedLocally.action_emplacement_douches_application,
								formSavedLocally.action_moyens_alerte_description,
								formSavedLocally.action_moyens_alerte_responsable,
								formSavedLocally.action_moyens_alerte_application,
								formSavedLocally.action_localisation_extincteurs_description,
								formSavedLocally.action_localisation_extincteurs_responsable,
								formSavedLocally.action_localisation_extincteurs_application,
								formSavedLocally.action_harnais_controle_description,
								formSavedLocally.action_harnais_controle_responsable,
								formSavedLocally.action_harnais_controle_application,
								formSavedLocally.action_cadenas_consignation_description,
								formSavedLocally.action_cadenas_consignation_responsable,
								formSavedLocally.action_cadenas_consignation_application,
								formSavedLocally.action_etat_vestiaires_description,
								formSavedLocally.action_etat_vestiaires_responsable,
								formSavedLocally.action_etat_vestiaires_application,
								formSavedLocally.action_etat_sanitaires_description,
								formSavedLocally.action_etat_sanitaires_responsable,
								formSavedLocally.action_etat_sanitaires_application,
								formSavedLocally.action_tri_dechets_description,
								formSavedLocally.action_tri_dechets_responsable,
								formSavedLocally.action_tri_dechets_application,
								formSavedLocally.action_emplacement_bennes_description,
								formSavedLocally.action_emplacement_bennes_responsable,
								formSavedLocally.action_emplacement_bennes_application,

								// Step 5

								formSavedLocally.date_signature
							);
							return tmpForm;
						case 'rapport_incident' :
							tmpForm = new RapportIncidentObject(
								formSavedLocally.id,
								formSavedLocally.createdAt,
								formSavedLocally.updatedAt,
								formSavedLocally.status,
								formSavedLocally.createdBy,
								formSavedLocally.updatedBy,

								// Step 1

								formSavedLocally.id_affaire,

								// Step 2

								form.affaire != null ? form.affaire.nom_client : null,
								form.affaire != null ? form.affaire.code_client : null,
								form.affaire != null ? form.affaire.nom : null,
								formSavedLocally.nature_evenement,

								// Step 3

								formSavedLocally.id_victime,
								formSavedLocally.type_contrat_victime,
								formSavedLocally.autres_personnes,
								formSavedLocally.arret_travail,
								formSavedLocally.arret_travail_nb_jours,
								formSavedLocally.id_personne_concernee,
								formSavedLocally.type_contrat_personne_concernee,
								formSavedLocally.autres_personnes_concernees,
								formSavedLocally.autres_personnes_concernees_noms_prenoms,
								formSavedLocally.date_evenement,
								formSavedLocally.cause_tier,
								formSavedLocally.temoins,
								formSavedLocally.temoins_noms,
								formSavedLocally.temoins_autre,
								formSavedLocally.description,
								formSavedLocally.photos,
								formSavedLocally.premiers_soins,
								formSavedLocally.premiers_soins_qui,
								formSavedLocally.appel_secours,
								formSavedLocally.evacuation,
								formSavedLocally.evacuation_qui,
								formSavedLocally.evacuation_ou,
								formSavedLocally.horaires_effectuees,
								formSavedLocally.lieu_accident,

								// Step 4

								formSavedLocally.image_corps_face,
								formSavedLocally.image_corps_dos,
								formSavedLocally.image_visage,
								formSavedLocally.image_main,
								formSavedLocally.image_pied,
								formSavedLocally.lesions_precisions,
								formSavedLocally.nature_lesions_plaie,
								formSavedLocally.nature_lesions_entorse,
								formSavedLocally.nature_lesions_hematome,
								formSavedLocally.nature_lesions_asphyxie,
								formSavedLocally.nature_lesions_brulure,
								formSavedLocally.nature_lesions_fracture,
								formSavedLocally.nature_lesions_douleurs,
								formSavedLocally.nature_lesions_malaise,
								formSavedLocally.nature_lesions_autre,
								formSavedLocally.nature_lesions_precisions,
								formSavedLocally.vehicule_dos,
								formSavedLocally.vehicule_face,
								formSavedLocally.vehicule_droite,
								formSavedLocally.vehicule_gauche,
								formSavedLocally.dommages_precisions,

								// Step 5

								formSavedLocally.date_signature,
								formSavedLocally.date_signature_victime,
								formSavedLocally.date_signature_personne_concernee,
								formSavedLocally.temoins_signatures,

								// Step 6

								formSavedLocally.analyse_cause,
								formSavedLocally.date_analyse,
								formSavedLocally.causes_descriptions,
								formSavedLocally.causes_pieces_jointes,
								formSavedLocally.prevention_immediates_description,
								formSavedLocally.prevention_immediates_par,
								formSavedLocally.prevention_immediates_par_autre,
								formSavedLocally.prevention_correctives_description,
								formSavedLocally.prevention_correctives_par,
								formSavedLocally.prevention_correctives_par_autre,
								formSavedLocally.prevention_correctives_date_prevue,

								// Step 7

								formSavedLocally.date_signature_victime_2,
								formSavedLocally.date_signature_personne_concernee_2,
								formSavedLocally.temoins_signatures_2,
								formSavedLocally.id_hse,
								formSavedLocally.date_signature_hse,
								formSavedLocally.id_chef_chantier,
								formSavedLocally.date_signature_chantier,
								formSavedLocally.id_charge_affaire,
								formSavedLocally.date_signature_affaire,
								formSavedLocally.personnes_supplementaires,

								// Step 8

								formSavedLocally.prevention_correctives_date_reel,

								// Step 9

								formSavedLocally.date_signature_hse_2
							);
							return tmpForm;

						case 'consigne_harnais' :
							return new ConsigneHarnaisObject(
								formSavedLocally.id,
								formSavedLocally.createdAt,
								formSavedLocally.updatedAt,
								formSavedLocally.status,
								formSavedLocally.createdBy,
								formSavedLocally.updatedBy,

								// Step 1

								formSavedLocally.date_signature,
								formSavedLocally.signatories,
								formSavedLocally.signatories_dates
							);

						// Production

						case 'ouverture_affaire' :
							return new OuvertureAffaireObject(
								formSavedLocally.id,
								formSavedLocally.createdAt,
								formSavedLocally.updatedAt,
								formSavedLocally.status,
								formSavedLocally.createdBy,
								formSavedLocally.updatedBy,

								// Step 1

								formSavedLocally.numero_devis,

								// Step 2

								formSavedLocally.nom_client,
								formSavedLocally.numero_devis_comp,
								formSavedLocally.interlocuteur,
								formSavedLocally.titre,
								formSavedLocally.prix,
								formSavedLocally.heures,
								formSavedLocally.materiel,

								// Step 3

								formSavedLocally.charge_affaire,
								formSavedLocally.assistant_charge_affaire,
								formSavedLocally.responsable_chantier,
								formSavedLocally.assistante,

								// Step 4

								formSavedLocally.initials,
								formSavedLocally.id_affaire,
								formSavedLocally.numero_commande
							);
						case 'demande_travaux' :
							return new DemandeTravauxObject(
								formSavedLocally.id,
								formSavedLocally.createdAt,
								formSavedLocally.updatedAt,
								formSavedLocally.status,
								formSavedLocally.createdBy,
								formSavedLocally.updatedBy,

								// Step 1

								formSavedLocally.id_affaire,

								// Step 2

								form.affaire != null ? form.affaire.nom_client : null,
								form.affaire != null ? form.affaire.code_client : null,
								form.affaire != null ? form.affaire.contact : null,
								form.affaire != null ? form.affaire.charge_affaire : null,
								form.affaire != null ? form.affaire.nom : null,
								formSavedLocally.adresse_chantier,
								formSavedLocally.no_devis,
								form.affaire != null ? form.affaire.no_commande : null,
								formSavedLocally.numero_affaire,
								formSavedLocally.id_assistant_charge_affaire,

								// Step 3

								formSavedLocally.infos_importantes,
								formSavedLocally.etude_electrique,
								formSavedLocally.id_resp_elec,
								formSavedLocally.etude_electrique_temps,
								formSavedLocally.etude_electrique_specificites,
								formSavedLocally.etude_electrique_description,
								formSavedLocally.etude_electrique_diffusion,
								formSavedLocally.etude_electrique_date_prevue,
								formSavedLocally.etude_electrique_commentaires,
								formSavedLocally.etude_automatisme,
								formSavedLocally.id_resp_auto,
								formSavedLocally.etude_automatisme_technicien,
								formSavedLocally.etude_automatisme_specificites,
								formSavedLocally.etude_automatisme_automate,
								formSavedLocally.etude_automatisme_ihm,
								formSavedLocally.etude_automatisme_supervision,
								formSavedLocally.etude_automatisme_variateur,
								formSavedLocally.etude_automatisme_analyse,
								formSavedLocally.etude_automatisme_manuel,
								formSavedLocally.etude_automatisme_cahier,
								formSavedLocally.etude_automatisme_temps_developpement,
								formSavedLocally.etude_automatisme_temps_essai,
								formSavedLocally.etude_automatisme_temps_service,
								formSavedLocally.etude_automatisme_date_essai,
								formSavedLocally.etude_automatisme_date_service,
								formSavedLocally.etude_automatisme_commentaires,
								formSavedLocally.atelier,
								formSavedLocally.id_chef_atelier,
								formSavedLocally.atelier_essai,
								formSavedLocally.atelier_date_livraison,
								formSavedLocally.atelier_adresse_livraison,
								formSavedLocally.atelier_mode_livraison,
								formSavedLocally.atelier_contrainte_livraison,
								formSavedLocally.atelier_specifications,
								formSavedLocally.atelier_temps_cablage,
								formSavedLocally.atelier_commentaires,
								formSavedLocally.chantier,
								formSavedLocally.chantier_chef,
								formSavedLocally.chantier_specificites,
								formSavedLocally.chantier_date_demarrage,
								formSavedLocally.chantier_date_fin,
								formSavedLocally.chantier_temps_estime,
								formSavedLocally.chantier_commentaires,
								formSavedLocally.ids_destinataires,
								formSavedLocally.analyse_necessaire
							);
						case 'cr_intervention' :
							tmpForm = new CrInterventionObject(
								formSavedLocally.id,
								formSavedLocally.createdAt,
								formSavedLocally.updatedAt,
								formSavedLocally.status,
								formSavedLocally.createdBy,
								formSavedLocally.updatedBy,

								// Step 1

								formSavedLocally.id_affaire,

								// Step 2

								formSavedLocally.client,
								formSavedLocally.interlocuteur,
								formSavedLocally.lieu,
								formSavedLocally.batiment,
								formSavedLocally.machine,
								formSavedLocally.intervenant_stemi,
								formSavedLocally.date_intervention,
								formSavedLocally.date_fin_intervention,
								formSavedLocally.heures_bureau,
								formSavedLocally.heures_chantier,
								formSavedLocally.heures_route,

								// Step 3

								formSavedLocally.description,
								formSavedLocally.materiel_fourni,
								formSavedLocally.commentaire,
								formSavedLocally.photos,

								// Step 4

								formSavedLocally.date_signature,
								formSavedLocally.client_reception_nom,
								formSavedLocally.client_reception_date,
								formSavedLocally.client_reception_signature
							);
							return tmpForm;
						case 'pv_reception' :
							return new PvReceptionObject(
								formSavedLocally.id,
								formSavedLocally.createdAt,
								formSavedLocally.updatedAt,
								formSavedLocally.status,
								formSavedLocally.createdBy,
								formSavedLocally.updatedBy,

								// Step 1

								formSavedLocally.id_affaire,

								// Step 2

								form.affaire != null ? form.affaire.nom_client : null,
								form.affaire != null ? form.affaire.code_client : null,
								form.affaire != null ? form.affaire.contact : null,
								form.affaire != null ? form.affaire.charge_affaire : null,
								form.affaire != null ? form.affaire.nom : null,
								form.affaire != null ? form.affaire.no_devis : null,
								form.affaire != null ? form.affaire.no_commande : null,
								formSavedLocally.sous_traitant,

								// Step 3

								formSavedLocally.reception,
								formSavedLocally.travaux,
								formSavedLocally.complementaires,
								formSavedLocally.commentaires,

								// Step 4

								formSavedLocally.date_signature,
								formSavedLocally.client_reception_nom,
								formSavedLocally.client_reception_date,
								formSavedLocally.client_reception_signature,
								formSavedLocally.sous_traitant_nom,
								formSavedLocally.sous_traitant_societe,
								formSavedLocally.sous_traitant_date_signature,
								formSavedLocally.sous_traitant_signature,
							);
						case 'fiche_qualite' :
							tmpForm = new FicheQualiteObject(
								formSavedLocally.id,
								formSavedLocally.createdAt,
								formSavedLocally.updatedAt,
								formSavedLocally.status,
								formSavedLocally.createdBy,
								formSavedLocally.updatedBy,

								// Step 1

								formSavedLocally.id_affaire,

								// Step 2

								form.affaire != null ? form.affaire.nom_client : null,
								form.affaire != null ? form.affaire.code_client : null,
								form.affaire != null ? form.affaire.charge_affaire : null,
								form.affaire != null ? form.affaire.nom : null,
								formSavedLocally.photos,
								formSavedLocally.commentaire,
								formSavedLocally.actions_correctives,
								formSavedLocally.id_resp_tech,
								formSavedLocally.date_signature_resp_tech,
								formSavedLocally.suivi_actions,
								formSavedLocally.date_signature_resp_tech_2
							);
							return tmpForm;

						// Administratif

						case 'demande_absence' :
							return new DemandeAbsenceObject(
								formSavedLocally.id,
								formSavedLocally.createdAt,
								formSavedLocally.updatedAt,
								formSavedLocally.status,
								formSavedLocally.createdBy,
								formSavedLocally.updatedBy,

								// Step 1

								formSavedLocally.type,
								formSavedLocally.date_debut,
								formSavedLocally.date_fin,
								formSavedLocally.total_jours,
								formSavedLocally.commentaire,
								formSavedLocally.id_responsable,
								formSavedLocally.no_chrono,

								// Step 2

								formSavedLocally.date_signature
							);

						default :
							throw 'FORM_UNKNOWN';
					}
				}
			} else {
				const conf = {
					param_1: 'api',
					param_2: 'auth',
					param_3: 'form',
					param_4: form.table,
					param_5: form.id
				};
				const result: any = await this.webService.get(conf);
				if (result.data != null) {
					switch (form.table) {

						// Sécurité

						case 'accueil_securite' :
							const autres_signatures = [];
							const autres_signatures_dates = [];
							for (const i in result.data.autres_signatures) {
								autres_signatures.push(this.usersService.getUserFromId(result.data.autres_signatures[i].id_user, users));
								autres_signatures_dates.push(result.data.autres_signatures[i].date === 'null' ? null : moment(result.data.autres_signatures[i].date).format());
							}
							const personnes_supplementaires = [];
							for (const i in result.data.personnes_supplementaires) {
								if (result.data.personnes_supplementaires[i] && result.data.personnes_supplementaires[i] !== 'null') {
									if (result.data.personnes_supplementaires[i].id === 'null') {
										result.data.personnes_supplementaires[i].id = null;
									}
									if (result.data.personnes_supplementaires[i].societe === 'null') {
										result.data.personnes_supplementaires[i].societe = null;
									}
									if (result.data.personnes_supplementaires[i].nom === 'null') {
										result.data.personnes_supplementaires[i].nom = null;
									}
									if (result.data.personnes_supplementaires[i].type === 'null') {
										result.data.personnes_supplementaires[i].type = null;
									}
									if (result.data.personnes_supplementaires[i].date_signature === 'null') {
										result.data.personnes_supplementaires[i].date_signature = null;
									}
									if (result.data.personnes_supplementaires[i].signature !== null) {
										result.data.personnes_supplementaires[i].signature = {
											type: 'remote',
											data: result.data.personnes_supplementaires[i].signature
										};
									} else {
										result.data.personnes_supplementaires[i].signature = null;
									}
									personnes_supplementaires.push(result.data.personnes_supplementaires[i]);
								}
							}
							return new AccueilSecuriteObject(
								result.data.id,
								result.data.createdAt != null ? moment(result.data.createdAt).format() : null,
								result.data.updatedAt != null ? moment(result.data.updatedAt).format() : null,
								result.data.status,
								result.data.createdBy ? UserObject.LoadFromJSON(result.data.createdBy) : null,
								result.data.updatedBy ? UserObject.LoadFromJSON(result.data.updatedBy) : null,

								// Step 1

								result.data.id_affaire,

								// Step 2

								form.affaire != null ? form.affaire.nom_client : null,
								form.affaire != null ? form.affaire.code_client : null,
								form.affaire != null ? form.affaire.nom : null,
								form.affaire != null ? form.affaire.responsable : null,

								// Step 3

								result.data.aptitude_medicale === 'null' ? null : result.data.aptitude_medicale,
								result.data.aptitude_medicale_description === 'null' ? null : result.data.aptitude_medicale_description,
								result.data.autorisation_acces_chantier === 'null' ? null : result.data.autorisation_acces_chantier,
								result.data.autorisation_acces_chantier_description === 'null' ? null : result.data.autorisation_acces_chantier_description,
								result.data.titre_habilitation_electrique === 'null' ? null : result.data.titre_habilitation_electrique,
								result.data.titre_habilitation_electrique_description === 'null' ? null : result.data.titre_habilitation_electrique_description,
								result.data.titre_formation_chimique === 'null' ? null : result.data.titre_formation_chimique,
								result.data.titre_formation_chimique_description === 'null' ? null : result.data.titre_formation_chimique_description,
								result.data.cerfa_n1138302 === 'null' ? null : result.data.cerfa_n1138302,
								result.data.cerfa_n1138302_description === 'null' ? null : result.data.cerfa_n1138302_description,
								result.data.autorisation_conduite_nacelle === 'null' ? null : result.data.autorisation_conduite_nacelle,
								result.data.autorisation_conduite_nacelle_description === 'null' ? null : result.data.autorisation_conduite_nacelle_description,
								result.data.caces_nacelle === 'null' ? null : result.data.caces_nacelle,
								result.data.caces_nacelle_description === 'null' ? null : result.data.caces_nacelle_description,
								result.data.autorisation_echaffaudage === 'null' ? null : result.data.autorisation_echaffaudage,
								result.data.autorisation_echaffaudage_description === 'null' ? null : result.data.autorisation_echaffaudage_description,
								result.data.casque_vetements_travail_chaussures_securite === 'null' ? null : result.data.casque_vetements_travail_chaussures_securite,
								result.data.casque_vetements_travail_chaussures_securite_description === 'null' ? null : result.data.casque_vetements_travail_chaussures_securite_description,
								result.data.vat_casque_idra === 'null' ? null : result.data.vat_casque_idra,
								result.data.vat_casque_idra_description === 'null' ? null : result.data.vat_casque_idra_description,
								result.data.outillage_individuel === 'null' ? null : result.data.outillage_individuel,
								result.data.outillage_individuel_description === 'null' ? null : result.data.outillage_individuel_description,
								result.data.travail_cours_effectuer === 'null' ? null : result.data.travail_cours_effectuer,
								result.data.presence_signature_pdp_ppsps === 'null' ? null : result.data.presence_signature_pdp_ppsps,
								result.data.explication_visite_chantier_realises === 'null' ? null : result.data.explication_visite_chantier_realises,
								result.data.droit_alerte_retrait === 'null' ? null : result.data.droit_alerte_retrait,


								// Step 4

								result.data.risques_electriques,
								result.data.risques_electriques_details_basse_tension,
								result.data.risques_electriques_details_haute_tension,
								result.data.risques_electriques_details_voisinage_tension,
								result.data.risques_electriques_details_energie_permanente,
								result.data.risques_electriques_prevention_habilitation,
								result.data.risques_electriques_prevention_consignation,
								result.data.risques_electriques_prevention_voisinage,
								result.data.risques_lies_circulation,
								result.data.risques_lies_circulation_details_vehicules,
								result.data.risques_lies_circulation_details_pietonne,
								result.data.risques_lies_circulation_moyens_prevention_regles,
								result.data.risques_lies_circulation_moyens_prevention_baliser,
								result.data.risques_lies_circulation_moyens_prevention_epi,
								result.data.risques_chute_hauteur,
								result.data.risques_chute_hauteur_details_nacelle,
								result.data.risques_chute_hauteur_details_toiture,
								result.data.risques_chute_hauteur_details_echafaudage,
								result.data.risques_chute_hauteur_details_echelle,
								result.data.risques_chute_hauteur_details_harnais,
								result.data.risques_chute_hauteur_prevention_materiel,
								result.data.risques_chute_hauteur_prevention_conduite,
								result.data.risques_chute_hauteur_prevention_balisage,
								result.data.risques_chute_hauteur_prevention_harnais,
								result.data.risques_chute_hauteur_prevention_permis,
								result.data.risques_chute_charge_objet,
								result.data.risques_chute_charge_objet_prevention_casque,
								result.data.risques_chute_charge_objet_prevention_baliser,
								result.data.risques_mecaniques,
								result.data.risques_mecaniques_details_manipulation,
								result.data.risques_mecaniques_details_mecanisme,
								result.data.risques_mecaniques_details_electro,
								result.data.risques_mecaniques_prevention_gants,
								result.data.risques_mecaniques_prevention_etat,
								result.data.risques_mecaniques_prevention_balisage,
								result.data.risques_mecaniques_prevention_permis,
								result.data.risques_mecaniques_prevention_mecaniques,
								result.data.risques_chimiques,
								result.data.risques_chimiques_details_manipulation,
								result.data.risques_chimiques_details_voisinage,
								result.data.risques_chimiques_prevention_epi,
								result.data.risques_chimiques_prevention_produit,
								result.data.risques_chimiques_prevention_epi_client,
								result.data.risques_incendie,
								result.data.risques_incendie_details_soudure,
								result.data.risques_incendie_details_produits,
								result.data.risques_incendie_prevention_consignes,
								result.data.risques_incendie_prevention_fumer,
								result.data.risques_incendie_prevention_atex,
								result.data.risques_incendie_prevention_permis,
								result.data.risques_incendie_prevention_extincteurs,
								result.data.risques_nuisances,
								result.data.risques_nuisances_details_poussieres,
								result.data.risques_nuisances_details_bruit,
								result.data.risques_nuisances_prevention_auditives,
								result.data.risques_nuisances_prevention_masque,
								result.data.risques_nuisances_prevention_lunettes,
								result.data.risques_nuisances_prevention_zone,
								result.data.risques_lies_coactivite,
								result.data.risques_lies_coactivite_details_entreprises,
								result.data.risques_lies_coactivite_prevention_nature === 'null' ? null : result.data.risques_lies_coactivite_prevention_nature,
								result.data.risques_lies_coactivite_prevention_precaution === 'null' ? null : result.data.risques_lies_coactivite_prevention_precautions,
								result.data.risques_lies_coactivite_prevention_communication === 'null' ? null : result.data.risques_lies_coactivite_prevention_communication,
								result.data.risques_zone_alimentaire,
								result.data.risques_zone_alimentaire_prevention_regles,
								result.data.risques_zone_alimentaire_prevention_epi,
								result.data.autres_risques,
								result.data.autres_risques_type === 'null' ? null : result.data.autres_risques_type,
								result.data.autres_risques_prevention === 'null' ? null : result.data.autres_risques_prevention,

								// Step 5

								result.data.moyens_urgence_sortie,
								result.data.moyens_urgence_pharmacie,
								result.data.moyens_urgence_telephone,
								result.data.moyens_urgence_rassemblement,
								result.data.moyens_urgence_rincage,
								result.data.moyens_urgence_douche,
								result.data.moyens_urgence_extincteur,

								// Step 6

								result.data.date_signature != null ? moment(result.data.date_signature).format() : null,
								autres_signatures,
								autres_signatures_dates,
								personnes_supplementaires
							);
						case 'plan_prevention' :
							let signatureClientPlanPrevention = null;
							if (result.data.client_signature != null) {
								signatureClientPlanPrevention = {
									type: 'remote',
									data: result.data.client_signature
								};
							}
							return new PlanPreventionObject(
								result.data.id,
								result.data.createdAt != null ? moment(result.data.createdAt).format() : null,
								result.data.updatedAt != null ? moment(result.data.updatedAt).format() : null,
								result.data.status,
								result.data.createdBy ? UserObject.LoadFromJSON(result.data.createdBy) : null,
								result.data.updatedBy ? UserObject.LoadFromJSON(result.data.updatedBy) : null,

								// Step 1

								result.data.id_affaire,

								// Step 2

								result.data.nom_client === 'null' ? null : result.data.nom_client,
								form.affaire != null ? form.affaire.nom : null,
								result.data.interlocuteur === 'null' ? null : result.data.interlocuteur,
								result.data.telephone === 'null' ? null : result.data.telephone,
								result.data.entreprise_exterieure === 'null' ? null : result.data.entreprise_exterieure,
								result.data.entreprise_exterieure_responsable === 'null' ? null : result.data.entreprise_exterieure_responsable,
								result.data.entreprise_exterieure_telephone === 'null' ? null : result.data.entreprise_exterieure_telephone,
								this.usersService.getUserFromId(result.data.responsable_travaux, users),
								result.data.nb_stemi,
								result.data.lieu === 'null' ? null : result.data.lieu,
								result.data.date_debut_travaux != null ? moment(result.data.date_debut_travaux).format() : null,
								result.data.date_fin_travaux != null ? moment(result.data.date_fin_travaux).format() : null,
								result.data.horaires_intervention === null ? {debut: null, fin: null, debut_am: null, fin_am: null} : JSON.parse(result.data.horaires_intervention),
								result.data.presence_amiante === 'null' ? null : result.data.presence_amiante,
								result.data.localisation_amiante === 'null' ? null : result.data.localisation_amiante,

								// Step 3

								result.data.epi_chaussures_securite,
								result.data.epi_lunettes_protection,
								result.data.epi_protection_auditive,
								result.data.epi_casque_chantier,
								result.data.epi_vetements_travail,
								result.data.epi_gilet,

								// Step 4

								result.data.moyens_urgence_sortie,
								result.data.moyens_urgence_sortie_commentaire === 'null' ? null : result.data.moyens_urgence_sortie_commentaire,
								result.data.moyens_urgence_pharmacie,
								result.data.moyens_urgence_pharmacie_commentaire === 'null' ? null : result.data.moyens_urgence_pharmacie_commentaire,
								result.data.moyens_urgence_telephone,
								result.data.moyens_urgence_telephone_commentaire === 'null' ? null : result.data.moyens_urgence_telephone_commentaire,
								result.data.moyens_urgence_rassemblement,
								result.data.moyens_urgence_rassemblement_commentaire === 'null' ? null : result.data.moyens_urgence_rassemblement_commentaire,
								result.data.moyens_urgence_rincage,
								result.data.moyens_urgence_rincage_commentaire === 'null' ? null : result.data.moyens_urgence_rincage_commentaire,
								result.data.moyens_urgence_douche,
								result.data.moyens_urgence_douche_commentaire === 'null' ? null : result.data.moyens_urgence_douche_commentaire,
								result.data.moyens_urgence_extincteur,
								result.data.moyens_urgence_extincteur_commentaire === 'null' ? null : result.data.moyens_urgence_extincteur_commentaire,

								// Step 5

								result.data.prestataire,
								result.data.prestataire_societe === 'null' ? null : result.data.prestataire_societe,
								result.data.prestataire_responsable === 'null' ? null : result.data.prestataire_responsable,
								result.data.prestataire_nature_travaux === 'null' ? null : result.data.prestataire_nature_travaux,
								result.data.risques_electriques,
								result.data.risques_electriques_details_basse_tension,
								result.data.risques_electriques_details_haute_tension,
								result.data.risques_electriques_details_voisinage_tension,
								result.data.risques_electriques_prevention_habilitation,
								result.data.risques_electriques_prevention_habilitation_par_ee,
								result.data.risques_electriques_prevention_habilitation_par_eu,
								result.data.risques_electriques_prevention_consignation,
								result.data.risques_electriques_prevention_consignation_par_ee,
								result.data.risques_electriques_prevention_consignation_par_eu,
								result.data.risques_electriques_prevention_voisinage,
								result.data.risques_electriques_prevention_voisinage_par_ee,
								result.data.risques_electriques_prevention_voisinage_par_eu,
								result.data.risques_electriques_remarques === 'null' ? null : result.data.risques_electriques_remarques,
								result.data.risques_lies_circulation,
								result.data.risques_lies_circulation_details_vehicules,
								result.data.risques_lies_circulation_details_pietonne,
								result.data.risques_lies_circulation_moyens_prevention_regles,
								result.data.risques_lies_circulation_moyens_prevention_regles_par_ee,
								result.data.risques_lies_circulation_moyens_prevention_regles_par_eu,
								result.data.risques_lies_circulation_moyens_prevention_baliser,
								result.data.risques_lies_circulation_moyens_prevention_baliser_par_ee,
								result.data.risques_lies_circulation_moyens_prevention_baliser_par_eu,
								result.data.risques_lies_circulation_moyens_prevention_epi,
								result.data.risques_lies_circulation_moyens_prevention_epi_par_ee,
								result.data.risques_lies_circulation_moyens_prevention_epi_par_eu,
								result.data.risques_lies_circulation_remarques === 'null' ? null : result.data.risques_lies_circulation_remarques,
								result.data.risques_chute_hauteur,
								result.data.risques_chute_hauteur_details_nacelle,
								result.data.risques_chute_hauteur_details_echafaudage,
								result.data.risques_chute_hauteur_details_echelle,
								result.data.risques_chute_hauteur_prevention_materiel,
								result.data.risques_chute_hauteur_prevention_materiel_par_ee,
								result.data.risques_chute_hauteur_prevention_materiel_par_eu,
								result.data.risques_chute_hauteur_prevention_conduite,
								result.data.risques_chute_hauteur_prevention_conduite_par_ee,
								result.data.risques_chute_hauteur_prevention_conduite_par_eu,
								result.data.risques_chute_hauteur_prevention_balisage,
								result.data.risques_chute_hauteur_prevention_balisage_par_ee,
								result.data.risques_chute_hauteur_prevention_balisage_par_eu,
								result.data.risques_chute_hauteur_prevention_harnais,
								result.data.risques_chute_hauteur_prevention_harnais_par_ee,
								result.data.risques_chute_hauteur_prevention_harnais_par_eu,
								result.data.risques_chute_hauteur_prevention_permis,
								result.data.risques_chute_hauteur_prevention_permis_par_ee,
								result.data.risques_chute_hauteur_prevention_permis_par_eu,
								result.data.risques_chute_hauteur_prevention_pret_materiel,
								result.data.risques_chute_hauteur_remarques === 'null' ? null : result.data.risques_chute_hauteur_remarques,
								result.data.risques_chute_charge_objet,
								result.data.risques_chute_charge_objet_prevention_casque,
								result.data.risques_chute_charge_objet_prevention_casque_par_ee,
								result.data.risques_chute_charge_objet_prevention_casque_par_eu,
								result.data.risques_chute_charge_objet_prevention_baliser,
								result.data.risques_chute_charge_objet_prevention_baliser_par_ee,
								result.data.risques_chute_charge_objet_prevention_baliser_par_eu,
								result.data.risques_chute_charge_objet_remarques === 'null' ? null : result.data.risques_chute_charge_objet_remarques,
								result.data.risques_mecaniques,
								result.data.risques_mecaniques_details_mecanisme,
								result.data.risques_mecaniques_details_electro,
								result.data.risques_mecaniques_prevention_gants,
								result.data.risques_mecaniques_prevention_gants_par_ee,
								result.data.risques_mecaniques_prevention_gants_par_eu,
								result.data.risques_mecaniques_prevention_etat,
								result.data.risques_mecaniques_prevention_etat_par_ee,
								result.data.risques_mecaniques_prevention_etat_par_eu,
								result.data.risques_mecaniques_prevention_balisage,
								result.data.risques_mecaniques_prevention_balisage_par_ee,
								result.data.risques_mecaniques_prevention_balisage_par_eu,
								result.data.risques_mecaniques_prevention_permis,
								result.data.risques_mecaniques_prevention_permis_par_ee,
								result.data.risques_mecaniques_prevention_permis_par_eu,
								result.data.risques_mecaniques_remarques === 'null' ? null : result.data.risques_mecaniques_remarques,
								result.data.risques_chimiques,
								result.data.risques_chimiques_details_manipulation,
								result.data.risques_chimiques_details_voisinage,
								result.data.risques_chimiques_prevention_epi,
								result.data.risques_chimiques_prevention_epi_par_ee,
								result.data.risques_chimiques_prevention_epi_par_eu,
								result.data.risques_chimiques_prevention_produit,
								result.data.risques_chimiques_prevention_produit_par_ee,
								result.data.risques_chimiques_prevention_produit_par_eu,
								result.data.risques_chimiques_remarques === 'null' ? null : result.data.risques_chimiques_remarques,
								result.data.risques_incendie,
								result.data.risques_incendie_details_soudure,
								result.data.risques_incendie_details_produits,
								result.data.risques_incendie_prevention_consignes,
								result.data.risques_incendie_prevention_consignes_par_ee,
								result.data.risques_incendie_prevention_consignes_par_eu,
								result.data.risques_incendie_prevention_fumer,
								result.data.risques_incendie_prevention_fumer_par_ee,
								result.data.risques_incendie_prevention_fumer_par_eu,
								result.data.risques_incendie_prevention_atex,
								result.data.risques_incendie_prevention_atex_par_ee,
								result.data.risques_incendie_prevention_atex_par_eu,
								result.data.risques_incendie_prevention_permis,
								result.data.risques_incendie_prevention_permis_par_ee,
								result.data.risques_incendie_prevention_permis_par_eu,
								result.data.risques_incendie_prevention_extincteurs,
								result.data.risques_incendie_prevention_extincteurs_par_ee,
								result.data.risques_incendie_prevention_extincteurs_par_eu,
								result.data.risques_incendie_remarques === 'null' ? null : result.data.risques_incendie_remarques,
								result.data.risques_nuisances,
								result.data.risques_nuisances_details_poussieres,
								result.data.risques_nuisances_details_bruit,
								result.data.risques_nuisances_prevention_auditives,
								result.data.risques_nuisances_prevention_auditives_par_ee,
								result.data.risques_nuisances_prevention_auditives_par_eu,
								result.data.risques_nuisances_prevention_masque,
								result.data.risques_nuisances_prevention_masque_par_ee,
								result.data.risques_nuisances_prevention_masque_par_eu,
								result.data.risques_nuisances_prevention_lunettes,
								result.data.risques_nuisances_prevention_lunettes_par_ee,
								result.data.risques_nuisances_prevention_lunettes_par_eu,
								result.data.risques_nuisances_remarques === 'null' ? null : result.data.risques_nuisances_remarques,
								result.data.risques_lies_coactivite,
								result.data.risques_lies_coactivite_details_entreprises,
								result.data.risques_lies_coactivite_prevention_nature === 'null' ? null : result.data.risques_lies_coactivite_prevention_nature,
								result.data.risques_lies_coactivite_prevention_precautions === 'null' ? null : result.data.risques_lies_coactivite_prevention_precautions,
								result.data.risques_lies_coactivite_prevention_precautions_par_ee,
								result.data.risques_lies_coactivite_prevention_precautions_par_eu,
								result.data.risques_lies_coactivite_remarques === 'null' ? null : result.data.risques_lies_coactivite_remarques,
								result.data.risques_bacteriologiques,
								result.data.risques_bacteriologiques_details_zone_blanche,
								result.data.risques_bacteriologiques_prevention_regles_hygiene === 'null' ? null : result.data.risques_bacteriologiques_prevention_regles_hygiene,
								result.data.risques_bacteriologiques_prevention_regles_hygiene_par_ee,
								result.data.risques_bacteriologiques_prevention_regles_hygiene_par_eu,
								result.data.risques_bacteriologiques_prevention_port_epi,
								result.data.risques_bacteriologiques_prevention_port_epi_par_ee,
								result.data.risques_bacteriologiques_prevention_port_epi_par_eu,
								result.data.risques_bacteriologiques_remarques === 'null' ? null : result.data.risques_bacteriologiques_remarques,
								result.data.autres_risques,
								result.data.autres_risques_type === 'null' ? null : result.data.autres_risques_type,
								result.data.autres_risques_prevention === 'null' ? null : result.data.autres_risques_prevention,
								result.data.autres_risques_prevention_par_ee,
								result.data.autres_risques_prevention_par_eu,
								result.data.autres_risques_remarques === 'null' ? null : result.data.autres_risques_remarques,
								result.data.document_eu_plan_site,
								result.data.document_eu_autres,
								result.data.document_eu_autres_description === 'null' ? null : result.data.document_eu_autres_description,

								// Step 6

								result.data.date_signature != null ? moment(result.data.date_signature).format() : null,
								result.data.client_nom_signature === 'null' ? null : result.data.client_nom_signature,
								result.data.client_date_signature != null ? moment(result.data.client_date_signature).format() : null,
								signatureClientPlanPrevention,
							);
						case 'analyse_risques' :
							return new AnalyseRisquesObject(
								result.data.id,
								result.data.createdAt != null ? moment(result.data.createdAt).format() : null,
								result.data.updatedAt != null ? moment(result.data.updatedAt).format() : null,
								result.data.status,
								result.data.createdBy ? UserObject.LoadFromJSON(result.data.createdBy) : null,
								result.data.updatedBy ? UserObject.LoadFromJSON(result.data.updatedBy) : null,

								// Step 1

								result.data.id_affaire,

								// Step 2

								form.affaire != null ? form.affaire.nom_client : null,
								form.affaire != null ? form.affaire.code_client : null,
								form.affaire != null ? form.affaire.contact : null,
								form.affaire != null ? form.affaire.nom : null,
								form.affaire != null ? form.affaire.charge_affaire : null,
								this.usersService.getUserFromId(result.data.chef_chantier, users),
								form.affaire != null ? form.affaire.no_devis : null,

								// Step 3

								result.data.risques_presents_electriques,
								result.data.risques_presents_coupure,
								result.data.risques_presents_mecanique,
								result.data.risques_presents_manuelle,
								result.data.risques_presents_ecrasement,
								result.data.risques_presents_projection,
								result.data.risques_presents_incendie,
								result.data.risques_presents_brulure,
								result.data.risques_presents_bruit,
								result.data.risques_presents_chute_plain_pied,
								result.data.risques_presents_chute_hauteur,
								result.data.risques_presents_coactivite,
								result.data.risques_presents_circulation,
								result.data.risques_presents_trajet,
								result.data.risques_presents_engin,
								result.data.risques_presents_chimiques,
								result.data.risques_presents_thermique,
								result.data.risques_presents_chutes_objets,
								result.data.risques_presents_agroalimentaire,
								result.data.autres_risques,
								result.data.autres_risques_consequences === 'null' ? null : result.data.autres_risques_consequences,
								result.data.autres_risques_prevention === 'null' ? null : result.data.autres_risques_prevention,
								result.data.specificites_chantier === 'null' ? null : result.data.specificites_chantier,

								// Step 4

								result.data.date_signature != null ? moment(result.data.date_signature).format() : null,
								this.usersService.getUsersFromIds(result.data.ids_destinataires, users)
							);
						case 'inspection_chantier' :
							const photosRetrievedInspectionChantier = [];
							for (const i in result.data.photos) {
								photosRetrievedInspectionChantier.push(
									{
										type: 'remote',
										data: result.data.photos[i]
									}
								);
							}
							return new InspectionChantierObject(
								result.data.id,
								result.data.createdAt != null ? moment(result.data.createdAt).format() : null,
								result.data.updatedAt != null ? moment(result.data.updatedAt).format() : null,
								result.data.status,
								result.data.createdBy ? UserObject.LoadFromJSON(result.data.createdBy) : null,
								result.data.updatedBy ? UserObject.LoadFromJSON(result.data.updatedBy) : null,

								// Step 1

								result.data.id_affaire,

								// Step 2

								form.affaire != null ? form.affaire.nom_client : null,
								form.affaire != null ? form.affaire.code_client : null,
								form.affaire != null ? form.affaire.nom : null,
								form.affaire != null ? form.affaire.responsable : null,
								result.data.nature_travaux === 'null' ? null : result.data.nature_travaux,
								result.data.nb_stemi,
								result.data.nb_interim,
								this.usersService.getUsersFromIds(result.data.personne_stemi_auditee, users),
								this.usersService.getUsersFromIds(result.data.personne_interim_auditee, users),
								result.data.personnes_supplementaires,

								// Step 3

								result.data.voie_acces === 'null' ? null : result.data.voie_acces,
								result.data.voie_acces_precision === 'null' ? null : result.data.voie_acces_precision,
								result.data.chantier_signalise === 'null' ? null : result.data.chantier_signalise,
								result.data.chantier_signalise_precision === 'null' ? null : result.data.chantier_signalise_precision,
								result.data.sortie_secours_libre === 'null' ? null : result.data.sortie_secours_libre,
								result.data.sortie_secours_libre_precision === 'null' ? null : result.data.sortie_secours_libre_precision,
								result.data.chantier_ordre === 'null' ? null : result.data.chantier_ordre,
								result.data.chantier_ordre_precision === 'null' ? null : result.data.chantier_ordre_precision,
								result.data.dta === 'null' ? null : result.data.dta,
								result.data.dta_precision === 'null' ? null : result.data.dta_precision,
								result.data.autorisation_permis_valide === 'null' ? null : result.data.autorisation_permis_valide,
								result.data.autorisation_permis_valide_precision === 'null' ? null : result.data.autorisation_permis_valide_precision,
								result.data.manoeuvre_consignation === 'null' ? null : result.data.manoeuvre_consignation,
								result.data.manoeuvre_consignation_precision === 'null' ? null : result.data.manoeuvre_consignation_precision,
								result.data.permis_feu_extincteur === 'null' ? null : result.data.permis_feu_extincteur,
								result.data.permis_feu_extincteur_precision === 'null' ? null : result.data.permis_feu_extincteur_precision,
								result.data.permis_penetrer === 'null' ? null : result.data.permis_penetrer,
								result.data.permis_penetrer_precision === 'null' ? null : result.data.permis_penetrer_precision,
								result.data.personnel_stemi_identifie === 'null' ? null : result.data.personnel_stemi_identifie,
								result.data.personnel_stemi_identifie_precision === 'null' ? null : result.data.personnel_stemi_identifie_precision,
								result.data.personnel_interim_identifie === 'null' ? null : result.data.personnel_interim_identifie,
								result.data.personnel_interim_identifie_precision === 'null' ? null : result.data.personnel_interim_identifie_precision,
								result.data.accueils_hse === 'null' ? null : result.data.accueils_hse,
								result.data.accueils_hse_precision === 'null' ? null : result.data.accueils_hse_precision,
								result.data.plan_prevention === 'null' ? null : result.data.plan_prevention,
								result.data.plan_prevention_precision === 'null' ? null : result.data.plan_prevention_precision,
								result.data.analyse_risques === 'null' ? null : result.data.analyse_risques,
								result.data.analyse_risques_precision === 'null' ? null : result.data.analyse_risques_precision,
								result.data.risques_lies_chantier === 'null' ? null : result.data.risques_lies_chantier,
								result.data.risques_lies_chantier_precision === 'null' ? null : result.data.risques_lies_chantier_precision,
								result.data.risques_specifiques === 'null' ? null : result.data.risques_specifiques,
								result.data.risques_specifiques_precision === 'null' ? null : result.data.risques_specifiques_precision,
								result.data.habilitations_disponibles === 'null' ? null : result.data.habilitations_disponibles,
								result.data.habilitations_disponibles_precision === 'null' ? null : result.data.habilitations_disponibles_precision,
								result.data.habilitations_valides === 'null' ? null : result.data.habilitations_valides,
								result.data.habilitations_valides_precision === 'null' ? null : result.data.habilitations_valides_precision,
								result.data.sst_present === 'null' ? null : result.data.sst_present,
								result.data.sst_present_precision === 'null' ? null : result.data.sst_present_precision,
								result.data.echelle_etat === 'null' ? null : result.data.echelle_etat,
								result.data.echelle_etat_precision === 'null' ? null : result.data.echelle_etat_precision,
								result.data.echafaudage_conforme === 'null' ? null : result.data.echafaudage_conforme,
								result.data.echafaudage_conforme_precision === 'null' ? null : result.data.echafaudage_conforme_precision,
								result.data.fiches_autorisation === 'null' ? null : result.data.fiches_autorisation,
								result.data.fiches_autorisation_precision === 'null' ? null : result.data.fiches_autorisation_precision,
								result.data.nacelle_conforme === 'null' ? null : result.data.nacelle_conforme,
								result.data.nacelle_conforme_precision === 'null' ? null : result.data.nacelle_conforme_precision,
								result.data.etat_outillage === 'null' ? null : result.data.etat_outillage,
								result.data.etat_outillage_precision === 'null' ? null : result.data.etat_outillage_precision,
								result.data.epi === 'null' ? null : result.data.epi,
								result.data.epi_precision === 'null' ? null : result.data.epi_precision,
								result.data.epi_etat === 'null' ? null : result.data.epi_etat,
								result.data.epi_etat_precision === 'null' ? null : result.data.epi_etat_precision,
								result.data.risques_coactivite === 'null' ? null : result.data.risques_coactivite,
								result.data.risques_coactivite_precision === 'null' ? null : result.data.risques_coactivite_precision,
								result.data.vat_chantier === 'null' ? null : result.data.vat_chantier,
								result.data.vat_chantier_precision === 'null' ? null : result.data.vat_chantier_precision,
								result.data.emplacement_douches === 'null' ? null : result.data.emplacement_douches,
								result.data.emplacement_douches_precision === 'null' ? null : result.data.emplacement_douches_precision,
								result.data.moyens_alerte === 'null' ? null : result.data.moyens_alerte,
								result.data.moyens_alerte_precision === 'null' ? null : result.data.moyens_alerte_precision,
								result.data.localisation_extincteurs === 'null' ? null : result.data.localisation_extincteurs,
								result.data.localisation_extincteurs_precision === 'null' ? null : result.data.localisation_extincteurs_precision,
								result.data.harnais_controle === 'null' ? null : result.data.harnais_controle,
								result.data.harnais_controle_precision === 'null' ? null : result.data.harnais_controle_precision,
								result.data.cadenas_consignation === 'null' ? null : result.data.cadenas_consignation,
								result.data.cadenas_consignation_precision === 'null' ? null : result.data.cadenas_consignation_precision,
								result.data.etat_vestiaires === 'null' ? null : result.data.etat_vestiaires,
								result.data.etat_vestiaires_precision === 'null' ? null : result.data.etat_vestiaires_precision,
								result.data.etat_sanitaires === 'null' ? null : result.data.etat_sanitaires,
								result.data.etat_sanitaires_precision === 'null' ? null : result.data.etat_sanitaires_precision,
								result.data.tri_dechets === 'null' ? null : result.data.tri_dechets,
								result.data.tri_dechets_precision === 'null' ? null : result.data.tri_dechets_precision,
								result.data.emplacement_bennes === 'null' ? null : result.data.emplacement_bennes,
								result.data.emplacement_bennes_precision === 'null' ? null : result.data.emplacement_bennes_precision,
								result.data.actions_non_securitaires === 'null' ? null : result.data.actions_non_securitaires,
								result.data.actions_non_securitaires_precision === 'null' ? null : result.data.actions_non_securitaires_precision,
								result.data.actions_securitaires === 'null' ? null : result.data.actions_securitaires,
								result.data.actions_securitaires_precision === 'null' ? null : result.data.actions_securitaires_precision,
								result.data.situations_dangereuses === 'null' ? null : result.data.situations_dangereuses,
								result.data.situations_dangereuses_precision === 'null' ? null : result.data.situations_dangereuses_precision,
								photosRetrievedInspectionChantier,

								// step 4

								result.data.action_voie_acces_description === 'null' ? null : result.data.action_voie_acces_description,
								this.usersService.getUserFromId(result.data.action_voie_acces_responsable, users),
								result.data.action_voie_acces_application === 'null' ? null : result.data.action_voie_acces_application,
								result.data.action_chantier_signalise_description === 'null' ? null : result.data.action_chantier_signalise_description,
								this.usersService.getUserFromId(result.data.action_chantier_signalise_responsable, users),
								result.data.action_chantier_signalise_application === 'null' ? null : result.data.action_chantier_signalise_application,
								result.data.action_sortie_secours_libre_description === 'null' ? null : result.data.action_sortie_secours_libre_description,
								this.usersService.getUserFromId(result.data.action_sortie_secours_libre_responsable, users),
								result.data.action_sortie_secours_libre_application === 'null' ? null : result.data.action_sortie_secours_libre_application,
								result.data.action_chantier_ordre_description === 'null' ? null : result.data.action_chantier_ordre_description,
								this.usersService.getUserFromId(result.data.action_chantier_ordre_responsable, users),
								result.data.action_chantier_ordre_application === 'null' ? null : result.data.action_chantier_ordre_application,
								result.data.action_dta_description === 'null' ? null : result.data.action_dta_description,
								this.usersService.getUserFromId(result.data.action_dta_responsable, users),
								result.data.action_dta_application === 'null' ? null : result.data.action_dta_application,
								result.data.action_autorisation_permis_valide_description === 'null' ? null : result.data.action_autorisation_permis_valide_description,
								this.usersService.getUserFromId(result.data.action_autorisation_permis_valide_responsable, users),
								result.data.action_autorisation_permis_valide_application === 'null' ? null : result.data.action_autorisation_permis_valide_application,
								result.data.action_manoeuvre_consignation_description === 'null' ? null : result.data.action_manoeuvre_consignation_description,
								this.usersService.getUserFromId(result.data.action_manoeuvre_consignation_responsable, users),
								result.data.action_manoeuvre_consignation_application === 'null' ? null : result.data.action_manoeuvre_consignation_application,
								result.data.action_permis_feu_extincteur_description === 'null' ? null : result.data.action_permis_feu_extincteur_description,
								this.usersService.getUserFromId(result.data.action_permis_feu_extincteur_responsable, users),
								result.data.action_permis_feu_extincteur_application === 'null' ? null : result.data.action_permis_feu_extincteur_application,
								result.data.action_permis_penetrer_description === 'null' ? null : result.data.action_permis_penetrer_description,
								this.usersService.getUserFromId(result.data.action_permis_penetrer_responsable, users),
								result.data.action_permis_penetrer_application === 'null' ? null : result.data.action_permis_penetrer_application,
								result.data.action_personnel_stemi_identifie_description === 'null' ? null : result.data.action_personnel_stemi_identifie_description,
								this.usersService.getUserFromId(result.data.action_personnel_stemi_identifie_responsable, users),
								result.data.action_personnel_stemi_identifie_application === 'null' ? null : result.data.action_personnel_stemi_identifie_application,
								result.data.action_personnel_interim_identifie_description === 'null' ? null : result.data.action_personnel_interim_identifie_description,
								this.usersService.getUserFromId(result.data.action_personnel_interim_identifie_responsable, users),
								result.data.action_personnel_interim_identifie_application === 'null' ? null : result.data.action_personnel_interim_identifie_application,
								result.data.action_accueils_hse_description === 'null' ? null : result.data.action_accueils_hse_description,
								this.usersService.getUserFromId(result.data.action_accueils_hse_responsable, users),
								result.data.action_accueils_hse_application === 'null' ? null : result.data.action_accueils_hse_application,
								result.data.action_plan_prevention_description === 'null' ? null : result.data.action_plan_prevention_description,
								this.usersService.getUserFromId(result.data.action_plan_prevention_responsable, users),
								result.data.action_plan_prevention_application === 'null' ? null : result.data.action_plan_prevention_application,
								result.data.action_analyse_risques_description === 'null' ? null : result.data.action_analyse_risques_description,
								this.usersService.getUserFromId(result.data.action_analyse_risques_responsable, users),
								result.data.action_analyse_risques_application === 'null' ? null : result.data.action_analyse_risques_application,
								result.data.action_risques_lies_chantier_description === 'null' ? null : result.data.action_risques_lies_chantier_description,
								this.usersService.getUserFromId(result.data.action_risques_lies_chantier_responsable, users),
								result.data.action_risques_lies_chantier_application === 'null' ? null : result.data.action_risques_lies_chantier_application,
								result.data.action_risques_specifiques_description === 'null' ? null : result.data.action_risques_specifiques_description,
								this.usersService.getUserFromId(result.data.action_risques_specifiques_responsable, users),
								result.data.action_risques_specifiques_application === 'null' ? null : result.data.action_risques_specifiques_application,
								result.data.action_habilitations_disponibles_description === 'null' ? null : result.data.action_habilitations_disponibles_description,
								this.usersService.getUserFromId(result.data.action_habilitations_disponibles_responsable, users),
								result.data.action_habilitations_disponibles_application === 'null' ? null : result.data.action_habilitations_disponibles_application,
								result.data.action_habilitations_valides_description === 'null' ? null : result.data.action_habilitations_valides_description,
								this.usersService.getUserFromId(result.data.action_habilitations_valides_responsable, users),
								result.data.action_habilitations_valides_application === 'null' ? null : result.data.action_habilitations_valides_application,
								result.data.action_sst_present_description === 'null' ? null : result.data.action_sst_present_description,
								this.usersService.getUserFromId(result.data.action_sst_present_responsable, users),
								result.data.action_sst_present_application === 'null' ? null : result.data.action_sst_present_application,
								result.data.action_echelle_etat_description === 'null' ? null : result.data.action_echelle_etat_description,
								this.usersService.getUserFromId(result.data.action_echelle_etat_responsable, users),
								result.data.action_echelle_etat_application === 'null' ? null : result.data.action_echelle_etat_application,
								result.data.action_echafaudage_conforme_description === 'null' ? null : result.data.action_echafaudage_conforme_description,
								this.usersService.getUserFromId(result.data.action_echafaudage_conforme_responsable, users),
								result.data.action_echafaudage_conforme_application === 'null' ? null : result.data.action_echafaudage_conforme_application,
								result.data.action_fiches_autorisation_description === 'null' ? null : result.data.action_fiches_autorisation_description,
								this.usersService.getUserFromId(result.data.action_fiches_autorisation_responsable, users),
								result.data.action_fiches_autorisation_application === 'null' ? null : result.data.action_fiches_autorisation_application,
								result.data.action_nacelle_conforme_description === 'null' ? null : result.data.action_nacelle_conforme_description,
								this.usersService.getUserFromId(result.data.action_nacelle_conforme_responsable, users),
								result.data.action_nacelle_conforme_application === 'null' ? null : result.data.action_nacelle_conforme_application,
								result.data.action_etat_outillage_description === 'null' ? null : result.data.action_etat_outillage_description,
								this.usersService.getUserFromId(result.data.action_etat_outillage_responsable, users),
								result.data.action_etat_outillage_application === 'null' ? null : result.data.action_etat_outillage_application,
								result.data.action_epi_description === 'null' ? null : result.data.action_epi_description,
								this.usersService.getUserFromId(result.data.action_epi_responsable, users),
								result.data.action_epi_application === 'null' ? null : result.data.action_epi_application,
								result.data.action_epi_etat_description === 'null' ? null : result.data.action_epi_etat_description,
								this.usersService.getUserFromId(result.data.action_epi_etat_responsable, users),
								result.data.action_epi_etat_application === 'null' ? null : result.data.action_epi_etat_application,
								result.data.action_risques_coactivite_description === 'null' ? null : result.data.action_risques_coactivite_description,
								this.usersService.getUserFromId(result.data.action_risques_coactivite_responsable, users),
								result.data.action_risques_coactivite_application === 'null' ? null : result.data.action_risques_coactivite_application,
								result.data.action_vat_chantier_description === 'null' ? null : result.data.action_vat_chantier_description,
								this.usersService.getUserFromId(result.data.action_vat_chantier_responsable, users),
								result.data.action_vat_chantier_application === 'null' ? null : result.data.action_vat_chantier_application,
								result.data.action_emplacement_douches_description === 'null' ? null : result.data.action_emplacement_douches_description,
								this.usersService.getUserFromId(result.data.action_emplacement_douches_responsable, users),
								result.data.action_emplacement_douches_application === 'null' ? null : result.data.action_emplacement_douches_application,
								result.data.action_moyens_alerte_description === 'null' ? null : result.data.action_moyens_alerte_description,
								this.usersService.getUserFromId(result.data.action_moyens_alerte_responsable, users),
								result.data.action_moyens_alerte_application === 'null' ? null : result.data.action_moyens_alerte_application,
								result.data.action_localisation_extincteurs_description === 'null' ? null : result.data.action_localisation_extincteurs_description,
								this.usersService.getUserFromId(result.data.action_localisation_extincteurs_responsable, users),
								result.data.action_localisation_extincteurs_application === 'null' ? null : result.data.action_localisation_extincteurs_application,
								result.data.action_harnais_controle_description === 'null' ? null : result.data.action_harnais_controle_description,
								this.usersService.getUserFromId(result.data.action_harnais_controle_responsable, users),
								result.data.action_harnais_controle_application === 'null' ? null : result.data.action_harnais_controle_application,
								result.data.action_cadenas_consignation_description === 'null' ? null : result.data.action_cadenas_consignation_description,
								this.usersService.getUserFromId(result.data.action_cadenas_consignation_responsable, users),
								result.data.action_cadenas_consignation_application === 'null' ? null : result.data.action_cadenas_consignation_application,
								result.data.action_etat_vestiaires_description === 'null' ? null : result.data.action_etat_vestiaires_description,
								this.usersService.getUserFromId(result.data.action_etat_vestiaires_responsable, users),
								result.data.action_etat_vestiaires_application === 'null' ? null : result.data.action_etat_vestiaires_application,
								result.data.action_etat_sanitaires_description === 'null' ? null : result.data.action_etat_sanitaires_description,
								this.usersService.getUserFromId(result.data.action_etat_sanitaires_responsable, users),
								result.data.action_etat_sanitaires_application === 'null' ? null : result.data.action_etat_sanitaires_application,
								result.data.action_tri_dechets_description === 'null' ? null : result.data.action_tri_dechets_description,
								this.usersService.getUserFromId(result.data.action_tri_dechets_responsable, users),
								result.data.action_tri_dechets_application === 'null' ? null : result.data.action_tri_dechets_application,
								result.data.action_emplacement_bennes_description === 'null' ? null : result.data.action_emplacement_bennes_description,
								this.usersService.getUserFromId(result.data.action_emplacement_bennes_responsable, users),
								result.data.action_emplacement_bennes_application === 'null' ? null : result.data.action_emplacement_bennes_application,

								// Step 5

								result.data.date_signature != null ? moment(result.data.date_signature).format() : null
							);
						case 'rapport_incident' :
							const photosRetrievedRapportIncident = [];
							for (const i in result.data.photos) {
								photosRetrievedRapportIncident.push(
									{
										type: 'remote',
										data: result.data.photos[i]
									}
								);
							}
							const piecesJointesRetrieved = [];
							for (const i in result.data.causes_pieces_jointes) {
								piecesJointesRetrieved.push(
									{
										type: 'remote',
										data: result.data.causes_pieces_jointes[i]
									}
								);
							}
							const temoins_noms = [];
							const temoins_signatures = [];
							const temoins_signatures_2 = [];
							for (const i in result.data.temoins_noms) {
								if (result.data.temoins_noms[i].nom != null) {
									temoins_noms.push(this.usersService.getUserFromId(result.data.temoins_noms[i].nom, users));
									temoins_signatures.push(result.data.temoins_noms[i].date_signature != null ? moment(result.data.temoins_noms[i].date_signature).format() : null);
									temoins_signatures_2.push(result.data.temoins_noms[i].date_signature_2 != null ? moment(result.data.temoins_noms[i].date_signature_2).format() : null);
								}
							}
							let imageCorpsFace;
							if (result.data.image_corps_face == null) {
								imageCorpsFace = {type: 'defaut', data: {url: './assets/imgs/image_corps_face.svg'}};
							} else {
								imageCorpsFace = {type: 'remote', data: result.data.image_corps_face};
							}
							let imageCorpsDos;
							if (result.data.image_corps_dos == null) {
								imageCorpsDos = {type: 'defaut', data: {url: './assets/imgs/image_corps_dos.svg'}};
							} else {
								imageCorpsDos = {type: 'remote', data: result.data.image_corps_dos};
							}
							let imageVisage;
							if (result.data.image_visage == null) {
								imageVisage = {type: 'defaut', data: {url: './assets/imgs/image_visage.svg'}};
							} else {
								imageVisage = {type: 'remote', data: result.data.image_visage};
							}
							let imageMain;
							if (result.data.image_main == null) {
								imageMain = {type: 'defaut', data: {url: './assets/imgs/image_main.svg'}};
							} else {
								imageMain = {type: 'remote', data: result.data.image_main};
							}
							let imagePied;
							if (result.data.image_pied == null) {
								imagePied = {type: 'defaut', data: {url: './assets/imgs/image_pied.svg'}};
							} else {
								imagePied = {type: 'remote', data: result.data.image_pied};
							}

							let vehiculeDos;
							if (result.data.vehicule_dos == null) {
								vehiculeDos = {type: 'defaut', data: {url: './assets/imgs/vehicule_dos.svg'}};
							} else {
								vehiculeDos = {type: 'remote', data: result.data.vehicule_dos};
							}
							let vehiculeFace;
							if (result.data.vehicule_face == null) {
								vehiculeFace = {type: 'defaut', data: {url: './assets/imgs/vehicule_face.svg'}};
							} else {
								vehiculeFace = {type: 'remote', data: result.data.vehicule_face};
							}
							let vehiculeDroite;
							if (result.data.vehicule_droite == null) {
								vehiculeDroite = {type: 'defaut', data: {url: './assets/imgs/vehicule_droite.svg'}};
							} else {
								vehiculeDroite = {type: 'remote', data: result.data.vehicule_droite};
							}
							let vehiculeGauche;
							if (result.data.vehicule_gauche == null) {
								vehiculeGauche = {type: 'defaut', data: {url: './assets/imgs/vehicule_gauche.svg'}};
							} else {
								vehiculeGauche = {type: 'remote', data: result.data.vehicule_gauche};
							}

							return new RapportIncidentObject(
								result.data.id,
								result.data.createdAt != null ? moment(result.data.createdAt).format() : null,
								result.data.updatedAt != null ? moment(result.data.updatedAt).format() : null,
								result.data.status,
								result.data.createdBy ? UserObject.LoadFromJSON(result.data.createdBy) : null,
								result.data.updatedBy ? UserObject.LoadFromJSON(result.data.updatedBy) : null,

								// Step 1

								result.data.id_affaire,

								// Step 2

								form.affaire != null ? form.affaire.nom_client : null,
								form.affaire != null ? form.affaire.code_client : null,
								form.affaire != null ? form.affaire.nom : null,
								result.data.nature_evenement === 'null' ? null : result.data.nature_evenement,

								// Step 3

								this.usersService.getUserFromId(result.data.id_victime, users),
								result.data.type_contrat_victime === 'null' ? null : result.data.type_contrat_victime,
								result.data.autres_personnes === 'null' ? null : result.data.autres_personnes,
								result.data.arret_travail === 'null' ? null : result.data.arret_travail,
								result.data.arret_travail_nb_jours,
								this.usersService.getUserFromId(result.data.id_personne_concernee, users),
								result.data.type_contrat_personne_concernee === 'null' ? null : result.data.type_contrat_personne_concernee,
								result.data.autres_personnes_concernees === 'null' ? null : result.data.autres_personnes_concernees,
								result.data.autres_personnes_concernees_noms_prenoms === 'null' ? null : result.data.autres_personnes_concernees_noms_prenoms,
								result.data.date_evenement != null ? moment(result.data.date_evenement).format() : null,
								result.data.cause_tier === 'null' ? null : result.data.cause_tier,
								result.data.temoins === 'null' ? null : result.data.temoins,
								temoins_noms,
								result.data.temoins_autre === 'null' ? null : result.data.temoins_autre,
								result.data.description === 'null' ? null : result.data.description,
								photosRetrievedRapportIncident,
								result.data.premiers_soins,
								result.data.premiers_soins_qui === 'null' ? null : result.data.premiers_soins_qui,
								result.data.appel_secours,
								result.data.evacuation,
								result.data.evacuation_qui === 'null' ? null : result.data.evacuation_qui,
								result.data.evacuation_ou === 'null' ? null : result.data.evacuation_ou,
								result.data.horaires_effectuees == null ? {start: null, end: null} : JSON.parse(result.data.horaires_effectuees),
								result.data.lieu_accident === 'null' ? null : result.data.lieu_accident,

								// Step 4

								imageCorpsFace,
								imageCorpsDos,
								imageVisage,
								imageMain,
								imagePied,
								result.data.lesions_precisions === 'null' ? null : result.data.lesions_precisions,
								result.data.nature_lesions_plaie,
								result.data.nature_lesions_entorse,
								result.data.nature_lesions_hematome,
								result.data.nature_lesions_asphyxie,
								result.data.nature_lesions_brulure,
								result.data.nature_lesions_fracture,
								result.data.nature_lesions_douleurs,
								result.data.nature_lesions_malaise,
								result.data.nature_lesions_autre,
								result.data.nature_lesions_precisions === 'null' ? null : result.data.nature_lesions_precisions,
								vehiculeDos,
								vehiculeFace,
								vehiculeDroite,
								vehiculeGauche,
								result.data.dommages_precisions === 'null' ? null : result.data.dommages_precisions,

								// Step 5

								result.data.date_signature != null ? moment(result.data.date_signature).format() : null,
								result.data.date_signature_victime != null ? moment(result.data.date_signature_victime).format() : null,
								result.data.date_signature_personne_concernee != null ? moment(result.data.date_signature_personne_concernee).format() : null,
								temoins_signatures,

								// Step 6

								result.data.analyse_cause === 'null' ? null : result.data.analyse_cause,
								result.data.date_analyse != null ? moment(result.data.date_analyse).format() : null,
								result.data.causes_descriptions === 'null' ? null : result.data.causes_descriptions,
								piecesJointesRetrieved,
								result.data.prevention_immediates_description === 'null' ? null : result.data.prevention_immediates_description,
								this.usersService.getUserFromId(result.data.prevention_immediates_par, users),
								result.data.prevention_immediates_par_autre === 'null' ? null : result.data.prevention_immediates_par_autre,
								result.data.prevention_correctives_description === 'null' ? null : result.data.prevention_correctives_description,
								this.usersService.getUserFromId(result.data.prevention_correctives_par, users),
								result.data.prevention_correctives_par_autre === 'null' ? null : result.data.prevention_correctives_par_autre,
								result.data.prevention_correctives_date_prevue != null ? moment(result.data.prevention_correctives_date_prevue).format() : null,

								// Step 7

								result.data.date_signature_victime_2 != null ? moment(result.data.date_signature_victime_2).format() : null,
								result.data.date_signature_personne_concernee_2 != null ? moment(result.data.date_signature_personne_concernee_2).format() : null,
								temoins_signatures_2,
								this.usersService.getUserFromId(result.data.id_hse, users),
								result.data.date_signature_hse != null ? moment(result.data.date_signature_hse).format() : null,
								this.usersService.getUserFromId(result.data.id_chef_chantier, users),
								result.data.date_signature_chantier != null ? moment(result.data.date_signature_chantier).format() : null,
								this.usersService.getUserFromId(result.data.id_charge_affaire, users),
								result.data.date_signature_affaire != null ? moment(result.data.date_signature_affaire).format() : null,
								result.data.personnes_supplementaires === 'null' ? null : result.data.personnes_supplementaires,

								// Step 8

								result.data.prevention_correctives_date_reel != null ? moment(result.data.prevention_correctives_date_reel).format() : null,

								// Step 9

								result.data.date_signature_hse_2 != null ? moment(result.data.date_signature_hse_2).format() : null
							);

						case 'consigne_harnais' :
							const signatories = [];
							const signatories_dates = [];
							for (const i in result.data.autres_signatures) {
								if (result.data.autres_signatures[i].id_user != null) {
									signatories.push(this.usersService.getUserFromId(result.data.autres_signatures[i].id_user, users));
									signatories_dates.push(result.data.autres_signatures[i].date != null ? moment(result.data.autres_signatures[i].date).format() : null);
								}
							}
							return new ConsigneHarnaisObject(
								result.data.id,
								result.data.createdAt != null ? moment(result.data.createdAt).format() : null,
								result.data.updatedAt != null ? moment(result.data.updatedAt).format() : null,
								result.data.status,
								result.data.createdBy ? UserObject.LoadFromJSON(result.data.createdBy) : null,
								result.data.updatedBy ? UserObject.LoadFromJSON(result.data.updatedBy) : null,


								// Step 1

								result.data.date_signature != null ? moment(result.data.date_signature).format() : null,
								signatories,
								signatories_dates
							);

						// Production

						case 'ouverture_affaire' :
							const numero_devis_comp = [];
							for (const i in result.data.numero_devis_comp) {
								numero_devis_comp.push({numero_devis: result.data.numero_devis_comp[i]});
							}
							return new OuvertureAffaireObject(
								result.data.id,
								result.data.createdAt != null ? moment(result.data.createdAt).format() : null,
								result.data.updatedAt != null ? moment(result.data.updatedAt).format() : null,
								result.data.status,
								result.data.createdBy ? UserObject.LoadFromJSON(result.data.createdBy) : null,
								result.data.updatedBy ? UserObject.LoadFromJSON(result.data.updatedBy) : null,

								// Step 1

								result.data.numero_devis,

								// Step 2

								result.data.nom_client === 'null' ? null : result.data.nom_client,
								numero_devis_comp,
								result.data.interlocuteur === 'null' ? null : result.data.interlocuteur,
								result.data.titre === 'null' ? null : result.data.titre,
								result.data.prix,
								result.data.heures,
								result.data.materiel,

								// Step 3

								this.usersService.getUserFromId(result.data.charge_affaire, users),
								this.usersService.getUserFromId(result.data.assistant_charge_affaire, users),
								this.usersService.getUserFromId(result.data.responsable_chantier, users),
								this.usersService.getUserFromId(result.data.assistante, users),

								// Step 4

								result.data.initials === 'null' ? null : result.data.initials,
								result.data.id_affaire,
								result.data.numero_commande === 'null' ? null : result.data.numero_commande,
							);
						case 'demande_travaux' :
							return new DemandeTravauxObject(
								result.data.id,
								result.data.createdAt != null ? moment(result.data.createdAt).format() : null,
								result.data.updatedAt != null ? moment(result.data.updatedAt).format() : null,
								result.data.status,
								result.data.createdBy ? UserObject.LoadFromJSON(result.data.createdBy) : null,
								result.data.updatedBy ? UserObject.LoadFromJSON(result.data.updatedBy) : null,

								// Step 1

								result.data.id_affaire,

								// Step 2

								form.affaire != null ? form.affaire.nom_client : null,
								form.affaire != null ? form.affaire.code_client : null,
								form.affaire != null ? form.affaire.contact : null,
								form.affaire != null ? form.affaire.charge_affaire : null,
								form.affaire != null ? form.affaire.nom : null,
								result.data.adresse_chantier === 'null' ? null : result.data.adresse_chantier,
								result.data.no_devis === 'null' ? null : result.data.no_devis,
								form.affaire != null ? form.affaire.no_commande : null,
								result.data.numero_affaire === 'null' ? null : result.data.numero_affaire,
								this.usersService.getUserFromId(result.data.id_assistant_charge_affaire, users),

								// Step 3

								result.data.infos_importantes === 'null' ? null : result.data.infos_importantes,
								result.data.etude_electrique === 'null' ? null : result.data.etude_electrique,
								this.usersService.getUserFromId(result.data.id_resp_elec, users),
								result.data.etude_electrique_temps === 'null' ? null : result.data.etude_electrique_temps,
								result.data.etude_electrique_specificites === 'null' ? null : result.data.etude_electrique_specificites,
								result.data.etude_electrique_description === 'null' ? null : result.data.etude_electrique_description,
								result.data.etude_electrique_diffusion === 'null' ? null : result.data.etude_electrique_diffusion,
								result.data.etude_electrique_date_prevue != null ? moment(result.data.etude_electrique_date_prevue).format() : null,
								result.data.etude_electrique_commentaires === 'null' ? null : result.data.etude_electrique_commentaires,
								result.data.etude_automatisme === 'null' ? null : result.data.etude_automatisme,
								this.usersService.getUserFromId(result.data.id_resp_auto, users),
								result.data.etude_automatisme_technicien === 'null' ? null : result.data.etude_automatisme_technicien,
								result.data.etude_automatisme_specificites === 'null' ? null : result.data.etude_automatisme_specificites,
								result.data.etude_automatisme_automate === 'null' ? null : result.data.etude_automatisme_automate,
								result.data.etude_automatisme_ihm === 'null' ? null : result.data.etude_automatisme_ihm,
								result.data.etude_automatisme_supervision === 'null' ? null : result.data.etude_automatisme_supervision,
								result.data.etude_automatisme_variateur === 'null' ? null : result.data.etude_automatisme_variateur,
								result.data.etude_automatisme_analyse === 'null' ? null : result.data.etude_automatisme_analyse,
								result.data.etude_automatisme_manuel === 'null' ? null : result.data.etude_automatisme_manuel,
								result.data.etude_automatisme_cahier === 'null' ? null : result.data.etude_automatisme_cahier,
								result.data.etude_automatisme_temps_developpement === 'null' ? null : result.data.etude_automatisme_temps_developpement,
								result.data.etude_automatisme_temps_essai === 'null' ? null : result.data.etude_automatisme_temps_essai,
								result.data.etude_automatisme_temps_service === 'null' ? null : result.data.etude_automatisme_temps_service,
								result.data.etude_automatisme_date_essai != null ? moment(result.data.etude_automatisme_date_essai).format() : null,
								result.data.etude_automatisme_date_service != null ? moment(result.data.etude_automatisme_date_service).format() : null,
								result.data.etude_automatisme_commentaires === 'null' ? null : result.data.etude_automatisme_commentaires,
								result.data.atelier === 'null' ? null : result.data.atelier,
								this.usersService.getUserFromId(result.data.id_chef_atelier, users),
								result.data.atelier_essai === 'null' ? null : result.data.atelier_essai,
								result.data.atelier_date_livraison != null ? moment(result.data.atelier_date_livraison).format() : null,
								result.data.atelier_adresse_livraison === 'null' ? null : result.data.atelier_adresse_livraison,
								result.data.atelier_mode_livraison === 'null' ? null : result.data.atelier_mode_livraison,
								result.data.atelier_contrainte_livraison === 'null' ? null : result.data.atelier_contrainte_livraison,
								result.data.atelier_specifications === 'null' ? null : result.data.atelier_specifications,
								result.data.atelier_temps_cablage === 'null' ? null : result.data.atelier_temps_cablage,
								result.data.atelier_commentaires === 'null' ? null : result.data.atelier_commentaires,
								result.data.chantier === 'null' ? null : result.data.chantier,
								this.usersService.getUserFromId(result.data.chantier_chef, users),
								result.data.chantier_specificites === 'null' ? null : result.data.chantier_specificites,
								result.data.chantier_date_demarrage != null ? moment(result.data.chantier_date_demarrage).format() : null,
								result.data.chantier_date_fin != null ? moment(result.data.chantier_date_fin).format() : null,
								result.data.chantier_temps_estime === 'null' ? null : result.data.chantier_temps_estime,
								result.data.chantier_commentaires === 'null' ? null : result.data.chantier_commentaires,
								this.usersService.getUsersFromIds(result.data.ids_destinataires, users),
								result.data.analyse_necessaire === 'null' ? null : result.data.analyse_necessaire
							);
						case 'cr_intervention' :
							const photosRetrievedICrIntervention = [];
							for (const i in result.data.photos) {
								photosRetrievedICrIntervention.push(
									{
										type: 'remote',
										data: result.data.photos[i]
									}
								);
							}
							let signatureClientCrIntervention = null;
							if (result.data.client_reception_signature != null) {
								signatureClientCrIntervention = {
									type: 'remote',
									data: result.data.client_reception_signature
								};
							}
							return new CrInterventionObject(
								result.data.id,
								result.data.createdAt != null ? moment(result.data.createdAt).format() : null,
								result.data.updatedAt != null ? moment(result.data.updatedAt).format() : null,
								result.data.status,
								result.data.createdBy ? UserObject.LoadFromJSON(result.data.createdBy) : null,
								result.data.updatedBy ? UserObject.LoadFromJSON(result.data.updatedBy) : null,

								// Step 1

								result.data.id_affaire,

								// Step 2

								result.data.client === 'null' ? null : result.data.client,
								result.data.interlocuteur === 'null' ? null : result.data.interlocuteur,
								result.data.lieu === 'null' ? null : result.data.lieu,
								result.data.batiment === 'null' ? null : result.data.batiment,
								result.data.machine === 'null' ? null : result.data.machine,
								this.usersService.getUsersFromIds(result.data.intervenant_stemi, users),
								result.data.date_intervention != null ? moment(result.data.date_intervention).format() : null,
								result.data.date_fin_intervention != null ? moment(result.data.date_fin_intervention).format() : null,
								result.data.heures_bureau,
								result.data.heures_chantier,
								result.data.heures_route,

								// Step 3

								result.data.description === 'null' ? null : result.data.description,
								result.data.materiel_fourni === 'null' ? null : result.data.materiel_fourni,
								result.data.commentaire === 'null' ? null : result.data.commentaire,
								photosRetrievedICrIntervention,

								// Step 4

								result.data.date_signature != null ? moment(result.data.date_signature).format() : null,
								result.data.client_reception_nom === 'null' ? null : result.data.client_reception_nom,
								result.data.client_reception_date != null ? moment(result.data.client_reception_date).format() : null,
								signatureClientCrIntervention
							);
						case 'pv_reception' :
							for (const i in result.data.travaux) {
								const photos = [];
								for (const j in result.data.travaux[i].photos) {
									photos.push(
										{
											type: 'remote',
											data: result.data.travaux[i].photos[j]
										}
									);
								}
								result.data.travaux[i].photos = photos;
							}
							let signatureClientPvReception = null;
							if (result.data.client_reception_signature != null) {
								signatureClientPvReception = {
									type: 'remote',
									data: result.data.client_reception_signature
								};
							}
							let signatureSousTraitantPvReception = null;
							if (result.data.sous_traitant_signature != null) {
								signatureSousTraitantPvReception = {
									type: 'remote',
									data: result.data.sous_traitant_signature
								};
							}
							return new PvReceptionObject(
								result.data.id,
								result.data.createdAt != null ? moment(result.data.createdAt).format() : null,
								result.data.updatedAt != null ? moment(result.data.updatedAt).format() : null,
								result.data.status,
								result.data.createdBy ? UserObject.LoadFromJSON(result.data.createdBy) : null,
								result.data.updatedBy ? UserObject.LoadFromJSON(result.data.updatedBy) : null,

								// Step 1

								result.data.id_affaire,

								// Step 2

								form.affaire != null ? form.affaire.nom_client : null,
								form.affaire != null ? form.affaire.code_client : null,
								form.affaire != null ? form.affaire.contact : null,
								form.affaire != null ? form.affaire.charge_affaire : null,
								form.affaire != null ? form.affaire.nom : null,
								form.affaire != null ? form.affaire.no_devis : null,
								form.affaire != null ? form.affaire.no_commande : null,
								result.data.sous_traitant,

								// Step 3

								result.data.reception === 'null' ? null : result.data.reception,
								result.data.travaux,
								result.data.complementaires === 'null' ? null : result.data.complementaires,
								result.data.commentaires === 'null' ? null : result.data.commentaires,

								// Step 4

								result.data.date_signature != null ? moment(result.data.date_signature).format() : null,
								result.data.client_reception_nom === 'null' ? null : result.data.client_reception_nom,
								result.data.client_reception_date != null ? moment(result.data.client_reception_date).format() : null,
								signatureClientPvReception,
								result.data.sous_traitant_nom === 'null' ? null : result.data.sous_traitant_nom,
								result.data.sous_traitant_societe === 'null' ? null : result.data.sous_traitant_societe,
								result.data.sous_traitant_date_signature != null ? moment(result.data.sous_traitant_date_signature).format() : null,
								signatureSousTraitantPvReception
							);
						case 'fiche_qualite' :
							const photosRetrievedFicheQualite = [];
							for (const i in result.data.photos) {
								photosRetrievedFicheQualite.push(
									{
										type: 'remote',
										data: result.data.photos[i]
									}
								);
							}
							return new FicheQualiteObject(
								result.data.id,
								result.data.createdAt != null ? moment(result.data.createdAt).format() : null,
								result.data.updatedAt != null ? moment(result.data.updatedAt).format() : null,
								result.data.status,
								result.data.createdBy ? UserObject.LoadFromJSON(result.data.createdBy) : null,
								result.data.updatedBy ? UserObject.LoadFromJSON(result.data.updatedBy) : null,

								// Step 1

								result.data.id_affaire,

								// Step 2

								form.affaire != null ? form.affaire.nom_client : null,
								form.affaire != null ? form.affaire.code_client : null,
								form.affaire != null ? form.affaire.charge_affaire : null,
								form.affaire != null ? form.affaire.nom : null,
								photosRetrievedFicheQualite,
								result.data.commentaire === 'null' ? null : result.data.commentaire,
								result.data.actions_correctives === 'null' ? null : result.data.actions_correctives,
								this.usersService.getUserFromId(result.data.id_resp_tech, users),
								result.data.date_signature_resp_tech != null ? moment(result.data.date_signature_resp_tech).format() : null,
								result.data.suivi_actions === 'null' ? null : result.data.suivi_actions,
								result.data.date_signature_resp_tech_2 != null ? moment(result.data.date_signature_resp_tech_2).format() : null
							);

						// Administratif

						case 'demande_absence' :
							return new DemandeAbsenceObject(
								result.data.id,
								result.data.createdAt != null ? moment(result.data.createdAt).format() : null,
								result.data.updatedAt != null ? moment(result.data.updatedAt).format() : null,
								result.data.status,
								result.data.createdBy ? UserObject.LoadFromJSON(result.data.createdBy) : null,
								result.data.updatedBy ? UserObject.LoadFromJSON(result.data.updatedBy) : null,


								// Step 1

								result.data.type === 'null' ? null : result.data.type,
								result.data.date_debut != null ? moment(result.data.date_debut).format() : null,
								result.data.date_fin != null ? moment(result.data.date_fin).format() : null,
								result.data.total_jours,
								result.data.commentaire === 'null' ? null : result.data.commentaire,
								this.usersService.getUserFromId(result.data.id_responsable, users),
								result.data.no_chrono === 'null' ? null : result.data.no_chrono,

								// Step 2

								result.data.date_signature != null ? moment(result.data.date_signature).format() : null
							);

						default :
							throw 'FORM_UNKNOWN';
					}
				} else {
					throw 'FORM_LOADING';
				}
			}
		} catch (error) {
			console.log(error);
			throw error;
		}
	}

	async duplicate(form, users: Array<UserObject>, userConnected: UserObject) {
		try {
			const formRetrieved: any = await this.getFormOld(form, users);
			switch (form.table) {
				case 'accueil_securite' :
					formRetrieved.id = null;
					formRetrieved.createdAt = moment().format();
					formRetrieved.updatedAt = moment().format();
					formRetrieved.status = 'draft';
					formRetrieved.createdBy = userConnected;
					formRetrieved.updatedBy = null;

					// Step 6

					formRetrieved.date_signature = null;
					formRetrieved.signatories = [];
					formRetrieved.signatories_dates = [];
					return formRetrieved;
				case 'plan_prevention':
					formRetrieved.id = null;
					formRetrieved.createdAt = moment().format();
					formRetrieved.updatedAt = moment().format();
					formRetrieved.status = 'draft';
					formRetrieved.createdBy = userConnected;
					formRetrieved.updatedBy = null;

					// Step 6

					formRetrieved.date_signature = null;
					formRetrieved.client_nom_signature = null;
					formRetrieved.client_date_signature = null;
					formRetrieved.client_signature = null;
					return formRetrieved;
				case 'rapport_incident' :
					formRetrieved.id = null;
					formRetrieved.createdAt = moment().format();
					formRetrieved.updatedAt = moment().format();
					formRetrieved.status = 'draft';
					formRetrieved.createdBy = userConnected;
					formRetrieved.updatedBy = null;

					// Step 3

					formRetrieved.id_victime = null;
					formRetrieved.type_contrat_victime = null;
					formRetrieved.arret_travail = null;
					formRetrieved.arret_travail_nb_jours = null;
					formRetrieved.id_personne_concernee = null;
					formRetrieved.type_contrat_personne_concernee = null;
					formRetrieved.horaires_effectuees = {start: null, end: null};
					formRetrieved.lieu_accident = null;

					// Step 4

					formRetrieved.image_corps_face = {type: 'defaut', data: {url: './assets/imgs/image_corps_face.svg'}};
					formRetrieved.image_corps_dos = {type: 'defaut', data: {url: './assets/imgs/image_corps_dos.svg'}};
					formRetrieved.image_visage = {type: 'defaut', data: {url: './assets/imgs/image_visage.svg'}};
					formRetrieved.image_main = {type: 'defaut', data: {url: './assets/imgs/image_main.svg'}};
					formRetrieved.image_pied = {type: 'defaut', data: {url: './assets/imgs/image_pied.svg'}};
					formRetrieved.lesions_precisions = null;
					formRetrieved.nature_lesions_plaie = false;
					formRetrieved.nature_lesions_entorse = false;
					formRetrieved.nature_lesions_hematome = false;
					formRetrieved.nature_lesions_asphyxie = false;
					formRetrieved.nature_lesions_brulure = false;
					formRetrieved.nature_lesions_fracture = false;
					formRetrieved.nature_lesions_douleurs = false;
					formRetrieved.nature_lesions_malaise = false;
					formRetrieved.nature_lesions_autre = false;
					formRetrieved.nature_lesions_precisions = null;
					formRetrieved.vehicule_dos = {type: 'defaut', data: {url: './assets/imgs/vehicule_dos.svg'}};
					formRetrieved.vehicule_face = {type: 'defaut', data: {url: './assets/imgs/vehicule_face.svg'}};
					formRetrieved.vehicule_droite = {type: 'defaut', data: {url: './assets/imgs/vehicule_droite.svg'}};
					formRetrieved.vehicule_gauche = {type: 'defaut', data: {url: './assets/imgs/vehicule_gauche.svg'}};
					formRetrieved.dommages_precisions = null;

					// Step 5

					formRetrieved.date_signature = null;
					formRetrieved.date_signature_victime = null;
					formRetrieved.date_signature_personne_concernee = null;
					formRetrieved.temoins_signatures = [];

					// Step 6

					formRetrieved.analyse_cause = null;
					formRetrieved.date_analyse = null;
					formRetrieved.causes_descriptions = null;
					formRetrieved.causes_pieces_jointes = null;
					formRetrieved.prevention_immediates_description = null;
					formRetrieved.prevention_immediates_par = null;
					formRetrieved.prevention_correctives_description = null;
					formRetrieved.prevention_correctives_par = null;
					formRetrieved.prevention_correctives_date_prevue = null;

					// Step 7

					formRetrieved.date_signature_victime_2 = null;
					formRetrieved.date_signature_personne_concernee_2 = null;
					formRetrieved.temoins_signatures_2 = [];
					formRetrieved.id_hse = null;
					formRetrieved.date_signature_hse = null;
					formRetrieved.id_chef_chantier = null;
					formRetrieved.date_signature_chantier = null;
					formRetrieved.id_charge_affaire = null;
					formRetrieved.date_signature_affaire = null;
					formRetrieved.personnes_supplementaires = null;

					// Step 8

					formRetrieved.prevention_correctives_date_reel = null;

					// Step 9

					formRetrieved.date_signature_hse_2 = null;

					return formRetrieved;
				default :
					throw 'FORM_UNKNOWN';
			}
		} catch (error) {
			throw error;
		}
	}

	async deleteForms(forms: Array<any>) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				throw 'NO_NETWORK';
			} else {
				const promises = [];
				for (const i in forms) {
					const conf = {
						param_1: 'api',
						param_2: 'auth',
						param_3: 'form',
						param_4: forms[i].table,
						param_5: forms[i].id
					};
					promises.push(this.webService.delete(conf));
				}
				await Promise.all(promises);
			}
		} catch (error) {
			throw error;
		}
	}

	async deleteAttachment(id) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				throw 'NO_NETWORK';
			} else {
				const conf = {
					param_1: 'api',
					param_2: 'auth',
					param_3: 'attachment',
					param_4: id
				};
				await this.webService.delete(conf);
			}
		} catch (error) {
			throw error;
		}
	}

	async deleteAccueiSecuritePersonneSupplementaire(id) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				throw 'NO_NETWORK';
			} else {
				const conf = {
					param_1: 'api',
					param_2: 'auth',
					param_3: 'form',
					param_4: 'accueil_securite_personne_supplementaire',
					param_5: id
				};
				await this.webService.delete(conf);
			}
		} catch (error) {
			throw error;
		}
	}

	async deletePvReceptionTravaux(id) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				throw 'NO_NETWORK';
			} else {
				const conf = {
					param_1: 'api',
					param_2: 'auth',
					param_3: 'form',
					param_4: 'pv_reception_travaux',
					param_5: id
				};
				await this.webService.delete(conf);
			}
		} catch (error) {
			throw error;
		}
	}

	async getAffaireInfo(idAffaire) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				return null;
			} else {
				const conf = {
					param_1: 'api',
					param_2: 'auth',
					param_3: 'form',
					param_4: 'affaire',
					param_5: idAffaire !== null ? idAffaire : -1
				};
				return await this.webService.get(conf);
			}
		} catch (error) {
			throw error;
		}
	}

	async getDevisInfo(noDevis) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				return null;
			} else {
				const conf = {
					param_1: 'api',
					param_2: 'auth',
					param_3: 'form',
					param_4: 'devis',
					param_5: noDevis ? noDevis : -1
				};
				return await this.webService.get(conf);
			}
		} catch (error) {
			throw error;
		}
	}

	async getFormPdf(form, users: Array<UserObject>, userConnected?: UserObject, isFullForm = false) {
		try {
			let formRetrieved: any;
			if (isFullForm) {
				formRetrieved = form;
			} else {
				formRetrieved = await this.getFormOld(form, users);
			}
			await AppUtils.delay(500);
			return await this.pdfService.generateFormPdf(formRetrieved, userConnected);
		} catch (error) {
			throw error;
		}
	}

	async sendMail(form, userConnected: UserObject) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				throw 'NO_NETWORK';
			} else {
				const pdfGenerated: any = await this.pdfService.generateFormPdf(form, userConnected);
				const pdfBlob: any = await this.fileService.getPdfBlob(pdfGenerated);
				let formData;
				if (pdfBlob == null) {
					throw 'FORM_PDF';
				} else {
					const users = await this.usersService.getUsers(true);
					const mailData = await this.getMailData(form, users);
					formData = new FormData();
					if (isDevMode()) {
						formData.append('from', 'jeremie.grosrosanvallon@anura.fr');
						formData.append('to[]', 'jeremie.grosrosanvallon@gmail.com');
						mailData.content += '<br><br>Destinataires :<br><br>';
						for (const i in mailData.to) {
							mailData.content += mailData.to[i] + '<br>';
						}
					} else {
						formData.append('from', 'no_reply@stemi.com');
						for (const i in mailData.to) {
							formData.append('to[]', mailData.to[i]);
						}
					}
					formData.append('subject', mailData.subject);
					formData.append('content', mailData.content);
					formData.append('attachment', pdfBlob.data, pdfBlob.fileName);
				}
				const conf = {
					param_1: 'api',
					param_2: 'auth',
					param_3: 'mystemi',
					param_4: 'send-email'
				};
				conf['formData'] = formData;
				await this.webService.post(conf);
			}
		} catch (error) {
			throw error;
		}
	}

	async sendMailWithoutAttachment(form) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				throw 'NO_NETWORK';
			} else {
				let formData;
				const users = await this.usersService.getUsers(true);
				const mailData = await this.getMailData(form, users);
				formData = new FormData();
				if (isDevMode()) {
					formData.append('from', 'jeremie.grosrosanvallon@anura.fr');
					formData.append('to[]', 'jeremie.grosrosanvallon@gmail.com');
					mailData.content += '<br><br>Destinataires :<br><br>';
					for (const i in mailData.to) {
						mailData.content += mailData.to[i] + '<br>';
					}
				} else {
					formData.append('from', 'no_reply@stemi.com');
					for (const i in mailData.to) {
						formData.append('to[]', mailData.to[i]);
					}
				}
				formData.append('subject', mailData.subject);
				formData.append('content', mailData.content);
				const conf = {
					param_1: 'api',
					param_2: 'auth',
					param_3: 'mystemi',
					param_4: 'send-email'
				};
				conf['formData'] = formData;
				await this.webService.post(conf);
			}
		} catch (error) {
			throw error;
		}
	}

	async getMailData(form, users: Array<UserObject>) {
		try {
			const activeUsers = users.filter((user) => {
				return user.actif;
			});
			const to = [];
			let subject = '';
			let content = '';
			let result: any;
			let affaire;
			let demandesTravaux: Array<DemandeTravauxObject>;
			switch (form.table) {
				case 'accueil_securite':
					result = await this.getAffaireInfo(form.id_affaire);
					affaire = result?.data;
					for (const i in activeUsers) {
						if (['profil_2'].includes(activeUsers[i].profil) && activeUsers[i].mail && !to.includes(activeUsers[i].mail)) {
							to.push(activeUsers[i].mail);
						}
					}
					subject = 'Affaire n° ' + affaire?.id + ' - ' + affaire?.nom + ' - Accueil sécurité';
					content = 'Bonjour,<br><br>Vous trouverez en pièce jointe l’accueil sécurité créé par <b>' + form.createdBy?.first_name + ' ' + form.createdBy?.last_name + '</b> et correspondant à l’affaire n°<b>' + affaire?.id + ' - ' + affaire?.nom + ' - ' + affaire?.nom_client + '</b>.<br><br>Vous souhaitant bonne réception.';
					break;
				case 'analyse_risques':
					result = await this.getAffaireInfo(form.id_affaire);
					affaire = result?.data;
					for (const i in activeUsers) {
						if (['profil_2'].includes(activeUsers[i].profil) && activeUsers[i].mail && !to.includes(activeUsers[i].mail)) {
							to.push(activeUsers[i].mail);
						}
						if (affaire?.charge_affaire && affaire.charge_affaire.toLowerCase().includes(activeUsers[i].first_name?.toLowerCase()) && affaire.charge_affaire.toLowerCase().includes(activeUsers[i].last_name?.toLowerCase()) && activeUsers[i].mail && !to.includes(activeUsers[i].mail)) {
							to.push(activeUsers[i].mail);
						}
					}
					if (form.chef_chantier?.mail && !to.includes(form.chef_chantier.mail)) {
						to.push(form.chef_chantier.mail);
					}
					for (const i in form.ids_destinataires) {
						if (form.ids_destinataires[i].mail && !to.includes(form.ids_destinataires[i].mail)) {
							to.push(form.ids_destinataires[i].mail);
						}
					}
					demandesTravaux = await this.getFormsOld('demande_travaux', [{name: 'demande_travaux.affaire', value: form.id_affaire}], users);
					if (demandesTravaux.length > 0 && demandesTravaux[0].id_assistant_charge_affaire?.mail && !to.includes(demandesTravaux[0].id_assistant_charge_affaire.mail)) {
						to.push(demandesTravaux[0].id_assistant_charge_affaire.mail);
					}
					subject = 'Affaire n° ' + affaire?.id + ' - ' + affaire?.nom + ' - Analyse des risques';
					content = 'Bonjour,<br><br>Vous trouverez en pièce jointe l’analyse des risques créée par <b>' + form.createdBy?.first_name + ' ' + form.createdBy?.last_name + '</b> et correspondant à l’affaire n°<b>' + affaire?.id + ' - ' + affaire?.nom + ' - ' + affaire?.nom_client + '</b>.<br><br>Vous souhaitant bonne réception.';
					break;
				case 'demande_travaux':
					result = await this.getAffaireInfo(form.id_affaire);
					affaire = result?.data;
					if (form.createdBy?.mail && !to.includes(form.createdBy.mail)) {
						to.push(form.createdBy.mail);
					}
					for (const i in activeUsers) {
						if (affaire?.charge_affaire && affaire.charge_affaire.toLowerCase().includes(activeUsers[i].first_name?.toLowerCase()) && affaire.charge_affaire.toLowerCase().includes(activeUsers[i].last_name?.toLowerCase()) && activeUsers[i].mail && !to.includes(activeUsers[i].mail)) {
							to.push(activeUsers[i].mail);
							break;
						}
					}
					if (form.etude_electrique === 'Oui' && form.id_resp_elec?.mail && !to.includes(form.id_resp_elec.mail)) {
						to.push(form.id_resp_elec.mail);
					}
					if (form.etude_automatisme === 'Oui' && form.id_resp_auto?.mail && !to.includes(form.id_resp_auto.mail)) {
						to.push(form.id_resp_auto.mail);
					}
					if (form.atelier === 'Oui' && form.id_chef_atelier?.mail && !to.includes(form.id_chef_atelier.mail)) {
						to.push(form.id_chef_atelier.mail);
					}
					if (form.etude_electrique === 'Oui' || form.etude_automatisme === 'Oui' || form.atelier === 'Oui') {
						for (const i in activeUsers) {
							if (['profil_16'].includes(activeUsers[i].profil) && activeUsers[i].mail && !to.includes(activeUsers[i].mail)) {
								to.push(activeUsers[i].mail);
							}
						}
					}
					if (form.chantier === 'Oui' && form.chantier_chef?.mail && !to.includes(form.chantier_chef.mail)) {
						to.push(form.chantier_chef.mail);
					}
					if (form.id_assistant_charge_affaire?.mail && !to.includes(form.id_assistant_charge_affaire.mail)) {
						to.push(form.id_assistant_charge_affaire.mail);
					}
					for (const i in form.ids_destinataires) {
						if (form.ids_destinataires[i].mail && !to.includes(form.ids_destinataires[i].mail)) {
							to.push(form.ids_destinataires[i].mail);
						}
					}
					subject = 'Affaire n° ' + affaire?.id + ' - ' + affaire?.nom + ' - Demande de travaux';
					content = 'Bonjour,<br><br>Vous trouverez en pièce jointe la demande de travaux créée par <b>' + form.createdBy?.first_name + ' ' + form.createdBy?.last_name + '</b> et correspondant à l’affaire n°<b>' + affaire?.id + ' - ' + affaire?.nom + ' - ' + affaire?.nom_client + '</b>.<br><br>Vous souhaitant bonne réception.';
					break;
				case 'inspection_chantier':
					result = await this.getAffaireInfo(form.id_affaire);
					affaire = result?.data;
					if (form.createdBy?.mail && !to.includes(form.createdBy.mail)) {
						to.push(form.createdBy.mail);
					}
					for (const i in activeUsers) {
						if (['profil_2'].includes(activeUsers[i].profil) && activeUsers[i].mail && !to.includes(activeUsers[i].mail)) {
							to.push(activeUsers[i].mail);
						}
						if (affaire?.charge_affaire && affaire.charge_affaire.toLowerCase().includes(activeUsers[i].first_name?.toLowerCase()) && affaire.charge_affaire.toLowerCase().includes(activeUsers[i].last_name?.toLowerCase()) && activeUsers[i].mail && !to.includes(activeUsers[i].mail)) {
							to.push(activeUsers[i].mail);
						}
					}
					demandesTravaux = await this.getFormsOld('demande_travaux', [{name: 'demande_travaux.affaire', value: form.id_affaire}], users);
					if (demandesTravaux.length > 0 && demandesTravaux[0].id_assistant_charge_affaire?.mail && !to.includes(demandesTravaux[0].id_assistant_charge_affaire.mail)) {
						to.push(demandesTravaux[0].id_assistant_charge_affaire.mail);
					}
					if (demandesTravaux.length > 0 && demandesTravaux[0].chantier_chef?.mail && !to.includes(demandesTravaux[0].chantier_chef.mail)) {
						to.push(demandesTravaux[0].chantier_chef.mail);
					}
					subject = 'Affaire n° ' + affaire?.id + ' - ' + affaire?.nom + ' - Inspection chantier';
					content = 'Bonjour,<br><br>Vous trouverez en pièce jointe le rapport d’inspection chantier réalisé par <b>' + form.createdBy?.first_name + ' ' + form.createdBy?.last_name + '</b> et correspondant à l’affaire n°<b>' + affaire?.id + ' - ' + affaire?.nom + ' - ' + affaire?.nom_client + '</b>.<br><br>Vous souhaitant bonne réception.';
					break;
				case 'rapport_incident':
					result = await this.getAffaireInfo(form.id_affaire);
					affaire = result?.data;
					for (const i in activeUsers) {
						if (['profil_1', 'profil_2', 'profil_7', 'profil_8', 'profil_14', 'profil_15', 'profil_16'].includes(activeUsers[i].profil) && activeUsers[i].mail && !to.includes(activeUsers[i].mail)) {
							to.push(activeUsers[i].mail);
						}
						if (affaire?.responsable && affaire.responsable.toLowerCase().includes(activeUsers[i].first_name?.toLowerCase()) && affaire.responsable.toLowerCase().includes(activeUsers[i].last_name?.toLowerCase()) && activeUsers[i].mail && !to.includes(activeUsers[i].mail)) {
							to.push(activeUsers[i].mail);
						}
					}
					if (!to.includes('thierry.trepot@stemi.com')) {
						to.push('thierry.trepot@stemi.com');
					}
					if (!to.includes('pa@stemi.com')) {
						to.push('pa@stemi.com');
					}
					if (!to.includes('julien.bornarel@stemi.com')) {
						to.push('julien.bornarel@stemi.com');
					}
					subject = 'Affaire n° ' + affaire?.id + ' - ' + affaire?.nom + ' - Rapport d’accident, soin, situation dangereuse';
					content = 'Bonjour,<br><br>Vous trouverez en pièce jointe le rapport d’accident, soin, situation dangereuse réalisé par <b>' + form.createdBy?.first_name + ' ' + form.createdBy?.last_name + '</b> et correspondant à l’affaire n°<b>' + affaire?.id + ' - ' + affaire?.nom + ' - ' + affaire?.nom_client + '</b>.<br><br>Vous souhaitant bonne réception.';
					break;
				case 'pv_reception':
					result = await this.getAffaireInfo(form.id_affaire);
					affaire = result?.data;
					if (form.createdBy?.mail && !to.includes(form.createdBy.mail)) {
						to.push(form.createdBy.mail);
					}
					for (const i in activeUsers) {
						if (affaire?.charge_affaire && affaire.charge_affaire.toLowerCase().includes(activeUsers[i].first_name?.toLowerCase()) && affaire.charge_affaire.toLowerCase().includes(activeUsers[i].last_name?.toLowerCase()) && activeUsers[i].mail && !to.includes(activeUsers[i].mail)) {
							to.push(activeUsers[i].mail);
						}
						if (affaire?.responsable && affaire.responsable.toLowerCase().includes(activeUsers[i].first_name?.toLowerCase()) && affaire.responsable.toLowerCase().includes(activeUsers[i].last_name?.toLowerCase()) && activeUsers[i].mail && !to.includes(activeUsers[i].mail)) {
							to.push(activeUsers[i].mail);
						}
					}
					subject = 'Affaire n° ' + affaire?.id + ' - ' + affaire?.nom + ' - PV de réception';
					content = 'Bonjour,<br><br>Vous trouverez en pièce jointe le PV de réception créé par <b>' + form.createdBy?.first_name + ' ' + form.createdBy?.last_name + '</b> et correspondant à l’affaire n°<b>' + affaire?.id + ' - ' + affaire?.nom + ' - ' + affaire?.nom_client + '</b>.<br><br>Vous souhaitant bonne réception.';
					break;
				case 'cr_intervention':
					result = await this.getAffaireInfo(form.id_affaire);
					affaire = result?.data;
					if (form.createdBy?.mail && !to.includes(form.createdBy.mail)) {
						to.push(form.createdBy.mail);
					}
					for (const i in activeUsers) {
						if (affaire?.charge_affaire && affaire.charge_affaire.toLowerCase().includes(activeUsers[i].first_name?.toLowerCase()) && affaire.charge_affaire.toLowerCase().includes(activeUsers[i].last_name?.toLowerCase()) && activeUsers[i].mail && !to.includes(activeUsers[i].mail)) {
							to.push(activeUsers[i].mail);
						}
						if (['profil_6', 'profil_16'].includes(activeUsers[i].profil) && activeUsers[i].mail && !to.includes(activeUsers[i].mail)) {
							to.push(activeUsers[i].mail);
						}
					}
					demandesTravaux = await this.getFormsOld('demande_travaux', [{name: 'demande_travaux.affaire', value: form.id_affaire}], users);
					if (demandesTravaux.length > 0 && demandesTravaux[0].id_assistant_charge_affaire?.mail && !to.includes(demandesTravaux[0].id_assistant_charge_affaire.mail)) {
						to.push(demandesTravaux[0].id_assistant_charge_affaire.mail);
					}
					const isDepannage = this.affaireIsDepannage(form.id_affaire);
					if (isDepannage) {
						subject = 'Affaire n° ' + affaire?.id + ' - ' + form.createdBy?.first_name + ' ' + form.createdBy?.last_name + ' - Compte-rendu intervention';
					} else {
						subject = 'Affaire n° ' + affaire?.id + ' - ' + affaire?.nom + ' - Compte-rendu intervention';
					}
					content = 'Bonjour,<br><br>Vous trouverez en pièce jointe le compte-rendu d’intervention créé par <b>' + form.createdBy?.first_name + ' ' + form.createdBy?.last_name + '</b> et correspondant à l’affaire n°<b>' + affaire?.id + (!isDepannage ? ' - ' + affaire?.nom + ' - ' + affaire?.nom_client : '') + '</b>.<br><br>Vous souhaitant bonne réception.';
					break;
				case 'fiche_qualite':
					result = await this.getAffaireInfo(form.id_affaire);
					affaire = result?.data;
					for (const i in activeUsers) {
						if (['profil_16'].includes(activeUsers[i].profil) && activeUsers[i].mail && !to.includes(activeUsers[i].mail)) {
							to.push(activeUsers[i].mail);
						}
					}
					subject = 'Affaire n° ' + affaire?.id + ' - ' + affaire?.nom + ' - Fiche qualité';
					switch (form.status) {
						case 'signed':
							content = 'Bonjour,<br><br>Vous trouverez en pièce jointe la Fiche Qualité créée par <b>' + form.createdBy?.first_name + ' ' + form.createdBy?.last_name + '</b> et correspondant à l’affaire n°<b>' + affaire?.id + ' - ' + affaire?.nom + ' - ' + affaire?.nom_client + '</b>.<br><br>Vous souhaitant bonne réception.';
							break;
						case 'validate':
							content = 'Bonjour,<br><br>Vous trouverez en pièce jointe la Fiche Qualité validée et correspondant à l’affaire n°<b>' + affaire?.id + ' - ' + affaire?.nom + ' - ' + affaire?.nom_client + '</b>.<br><br>Vous souhaitant bonne réception.';
							break;
						case 'closed':
							content = 'Bonjour,<br><br>Vous trouverez en pièce jointe la Fiche Qualité traitée et correspondant à l’affaire n°<b>' + affaire?.id + ' - ' + affaire?.nom + ' - ' + affaire?.nom_client + '</b>.<br><br>Vous souhaitant bonne réception.';
							break;
					}
					break;
				case 'demande_absence':
					if (form.createdBy?.mail && !to.includes(form.createdBy.mail)) {
						to.push(form.createdBy.mail);
					}
					if (form.id_responsable?.mail && !to.includes(form.id_responsable.mail)) {
						to.push(form.id_responsable.mail);
					}
					switch (form.status) {
						case 'to_be_validated':
							for (const i in activeUsers) {
								if (['profil_1'].includes(activeUsers[i].profil) && activeUsers[i].mail && !to.includes(activeUsers[i].mail)) {
									to.push(activeUsers[i].mail);
								}
							}
							subject = 'Demande d’absence à valider';
							content = 'Bonjour,<br><br>Vous avec reçu une demande d’absence de la part de <b>' + form.createdBy?.first_name + ' ' + form.createdBy?.last_name + '</b>.<br><br>Pour la valider, cliquez-ici https://mystemi.stemi.com<br><br>Vous souhaitant bonne réception.';
							break;
						case 'validate':
							for (const i in activeUsers) {
								if (['profil_1'].includes(activeUsers[i].profil) && activeUsers[i].mail && !to.includes(activeUsers[i].mail)) {
									to.push(activeUsers[i].mail);
								}
							}
							subject = 'Demande d’absence à traiter - ' + form.no_chrono;
							content = 'Bonjour,<br><br>La demande d’absence de <b>' + form.createdBy?.first_name + ' ' + form.createdBy?.last_name + '</b> a été validée.<br><br>Pour la traiter, cliquez-ici https://mystemi.stemi.com<br><br>Vous souhaitant bonne réception.';
							break;
						case 'rejected':
							subject = 'Demande d’absence refusée - ' + form.no_chrono;
							content = 'Bonjour,<br><br>La demande d’absence de <b>' + form.createdBy?.first_name + ' ' + form.createdBy?.last_name + '</b> a été refusée.<br><br>Vous souhaitant bonne réception.';
							break;
					}
					break;
				case 'consigne_harnais':
					if (form.createdBy?.mail && !to.includes(form.createdBy.mail)) {
						to.push(form.createdBy.mail);
					}
					for (const i in activeUsers) {
						if (['profil_2'].includes(activeUsers[i].profil) && activeUsers[i].mail && !to.includes(activeUsers[i].mail)) {
							to.push(activeUsers[i].mail);
						}
					}
					subject = 'Sensibilisation au port du harnais';
					content = 'Bonjour,<br><br>Nous vous informons que <b>' + form.createdBy?.first_name + ' ' + form.createdBy?.last_name + '</b> a lu la Sensibilisation au port du harnais aux personnes suivantes :<br><br>';
					for (const i in form.signatories) {
						content += '<b>' + form.signatories[i]?.first_name + ' ' + form.signatories[i]?.last_name + '</b><br>';
					}
					content += '<br>Vous souhaitant bonne réception.';
					break;
				case 'plan_prevention':
					result = await this.getAffaireInfo(form.id_affaire);
					affaire = result?.data;
					for (const i in activeUsers) {
						if (['profil_2'].includes(activeUsers[i].profil) && activeUsers[i].mail && !to.includes(activeUsers[i].mail)) {
							to.push(activeUsers[i].mail);
						}
						if (affaire?.charge_affaire && affaire.charge_affaire.toLowerCase().includes(activeUsers[i].first_name?.toLowerCase()) && affaire.charge_affaire.toLowerCase().includes(activeUsers[i].last_name?.toLowerCase()) && activeUsers[i].mail && !to.includes(activeUsers[i].mail)) {
							to.push(activeUsers[i].mail);
						}
					}
					demandesTravaux = await this.getFormsOld('demande_travaux', [{name: 'demande_travaux.affaire', value: form.id_affaire}], users);
					if (demandesTravaux.length > 0 && demandesTravaux[0].id_assistant_charge_affaire?.mail && !to.includes(demandesTravaux[0].id_assistant_charge_affaire.mail)) {
						to.push(demandesTravaux[0].id_assistant_charge_affaire.mail);
					}
					subject = 'Affaire n° ' + affaire?.id + ' - ' + affaire?.nom + ' - Plan de prévention';
					content = 'Bonjour,<br><br>Vous trouverez en pièce jointe le plan de prévention créé par <b>' + form.createdBy?.first_name + ' ' + form.createdBy?.last_name + '</b> et correspondant à l’affaire n°<b>' + affaire?.id + ' - ' + affaire?.nom + ' - ' + affaire?.nom_client + '</b>.<br><br>Vous souhaitant bonne réception.';
					break;
				case 'ouverture_affaire':
					if (form.createdBy?.mail && !to.includes(form.createdBy.mail)) {
						to.push(form.createdBy.mail);
					}
					if (form.charge_affaire?.mail && !to.includes(form.charge_affaire.mail)) {
						to.push(form.charge_affaire.mail);
					}
					if (form.assistant_charge_affaire?.mail && !to.includes(form.assistant_charge_affaire.mail)) {
						to.push(form.assistant_charge_affaire.mail);
					}
					if (form.assistante?.mail && !to.includes(form.assistante.mail)) {
						to.push(form.assistante.mail);
					}
					switch (form.status) {
						case 'to_be_completed':
							subject = 'Devis n° ' + form.numero_devis + ' - ' + form.nom_client + ' - Demande d’ouverture d’affaire';
							content = 'Bonjour,<br><br>Nous vous informons que <b>' + form.createdBy?.first_name + ' ' + form.createdBy?.last_name + '</b> a effectué(e) une demande d’ouverture d’affaire correspondant au devis n°<b>' + form.numero_devis + ' - ' + form.nom_client + '</b>.<br><br>Vous souhaitant bonne réception.';
							break;
						case 'signed':
							result = await this.getAffaireInfo(form.id_affaire);
							affaire = result?.data;
							let contentAffaire = '';
							if (affaire) {
								contentAffaire = affaire.id + ' - ' + affaire.nom + ' - ' + affaire.nom_client;
							} else {
								contentAffaire = form.id_affaire + ' - ' + form.titre_affaire + ' - ' + form.nom_client;
							}
							subject = 'Devis n° ' + form.numero_devis + ' - ' + form.nom_client + ' - Numéro d’affaire attribué';
							content = 'Bonjour,<br><br>Nous vous informons que le devis n°<b>' + form.numero_devis + '</b> est désormais attribué au numéro d’affaire n°<b>' + contentAffaire + '</b>.<br><br>Vous souhaitant bonne réception.';
							break;
					}
					break;
				case 'feuille_heure_semaine':
					for (const i in form.servicesConcernes) {
						if (form.servicesConcernes[i].service === 'etude-elec') {
							for (const j in activeUsers) {
								if (['profil_3'].includes(activeUsers[j].profil) && activeUsers[j].mail && !to.includes(activeUsers[j].mail)) {
									to.push(activeUsers[j].mail);
								}
							}
						} else if (form.servicesConcernes[i].service === 'etude-auto') {
							for (const j in activeUsers) {
								if (['profil_6'].includes(activeUsers[j].profil) && activeUsers[j].mail && !to.includes(activeUsers[j].mail)) {
									to.push(activeUsers[j].mail);
								}
							}
						} else if (form.servicesConcernes[i].service === 'atelier') {
							for (const j in activeUsers) {
								if (['profil_5'].includes(activeUsers[j].profil) && activeUsers[j].mail && !to.includes(activeUsers[j].mail)) {
									to.push(activeUsers[j].mail);
								}
							}
						} else if (form.servicesConcernes[i].service === 'chantier') {
							for (const j in activeUsers) {
								if (['profil_11'].includes(activeUsers[j].profil) && activeUsers[j].mail && !to.includes(activeUsers[j].mail)) {
									to.push(activeUsers[j].mail);
								}
							}
						}
					}
					subject = 'Notification de validation d’une feuille d’heures pour ' + form.user?.complete_name;
					content = 'Bonjour,<br><br>Vous avez une nouvelle feuille d’heures à valider pour l’un de vos collaborateurs.';
					content += '<br><br><b>Employé(e) :</b> ' + form.user?.complete_name;
					content += '<br><b>Semaine :</b> ' + moment(form.first_day_week).format('ddd DD/MM/YYYY') + ' - ' + moment(form.first_day_week).endOf('week').format('ddd DD/MM/YYYY');
					content += '<br><br>Merci de bien vouloir vérifier et valider cette feuille d’heures dès que possible.';
					content += '<br><br>Cordialement,<br><br>Service RH';
					break;
			}
			return {to, subject, content};
		} catch (error) {
			throw error;
		}
	}

	async getHomeData(userConnected: UserObject) {
		try {
			const networkStatus = await Network.getStatus();
			if (!this.platformIsDesktop && !networkStatus.connected) {
				const formsSavedLocally: any = await this.localDataService.getItem('forms_saved_locally');
				if (formsSavedLocally) {
					return {indicators: null, forms: formsSavedLocally};
				} else {
					throw 'NO_NETWORK';
				}
			} else {
				let promises = [];
				for (const i in AppConfig.forms) {
					const conf = {
						param_1: 'api',
						param_2: 'auth',
						param_3: 'form',
						param_4: AppConfig.forms[i].table,
						params: {
							group: 'custom_all',
							bypages: AppConfig.nbItemsPerPage,
						}
					};
					conf.params[AppConfig.forms[i].table + '.status'] = 'draft';
					conf.params[AppConfig.forms[i].table + '.createdBy'] = userConnected.id;
					promises.push(this.webService.get(conf));
				}
				let conf2 = {
					param_1: 'api',
					param_2: 'auth',
					param_3: 'form',
					param_4: 'demande_absence',
					params: {
						group: 'custom_all',
						bypages: AppConfig.nbItemsPerPage,
					}
				};
				conf2.params['demande_absence.status'] = 'to_be_validated';
				conf2.params['demande_absence.id_responsable'] = userConnected.id;
				promises.push(this.webService.get(conf2));
				if (['profil_8', 'profil_14', 'profil_15'].includes(userConnected.profil)) {
					conf2 = {
						param_1: 'api',
						param_2: 'auth',
						param_3: 'form',
						param_4: 'ouverture_affaire',
						params: {
							group: 'custom_all',
							bypages: AppConfig.nbItemsPerPage,
						}
					};
					conf2.params['ouverture_affaire.status'] = 'to_be_completed';
					promises.push(this.webService.get(conf2));
				}
				const home = {indicators_year: null, indicators_month: null, forms: []};
				let result = await Promise.all(promises);
				for (const i in result) {
					for (const j in result[i].data.items) {
						if (Number(i) < AppConfig.forms.length) {
							result[i].data.items[j].titre = AppConfig.forms[i].titre;
							result[i].data.items[j].table = AppConfig.forms[i].table;
						} else if (Number(i) === AppConfig.forms.length) {
							result[i].data.items[j].titre = 'Demande d\'absence';
							result[i].data.items[j].table = 'demande_absence';
						} else if (Number(i) === AppConfig.forms.length + 1) {
							result[i].data.items[j].titre = 'Demande d\'ouverture d\'affaire';
							result[i].data.items[j].table = 'ouverture_affaire';
						}
						if (result[i].data.items[j].createdBy) {
							result[i].data.items[j].createdBy = UserObject.LoadFromJSON(result[i].data.items[j].createdBy);
						}
						if (result[i].data.items[j].updatedBy) {
							result[i].data.items[j].updatedBy = UserObject.LoadFromJSON(result[i].data.items[j].updatedBy);
						}
						home.forms.push(result[i].data.items[j]);
					}
				}
				promises = [];
				promises.push(this.indicatorsService.getIndicators([moment().startOf('year').format(), moment().endOf('day').format()]));
				promises.push(this.indicatorsService.getIndicators([moment().startOf('month').format(), moment().endOf('day').format()]));
				result = await Promise.all(promises);
				home.indicators_year = result[0];
				home.indicators_month = result[1];
				if (this.platformIsDesktop) {
					return home;
				} else {
					const formsSavedLocally = await this.localDataService.getItem('forms_saved_locally');
					if (formsSavedLocally) {
						const formsSavedLocallyToDelete = [];
						for (const i in formsSavedLocally) {
							let savedLocallyFound = false;
							for (const j in home.forms) {
								if (formsSavedLocally[i].id === home.forms[j].id && formsSavedLocally[i].table === home.forms[j].table) {
									savedLocallyFound = true;
									if (moment(formsSavedLocally[i].updatedAt).format() > moment(home.forms[j].updatedAt).format()) {
										home.forms[j] = formsSavedLocally[i];
									} else {
										formsSavedLocallyToDelete.push(formsSavedLocally[i]);
									}
									break;
								}
							}
							if (!savedLocallyFound) {
								home.forms.push(formsSavedLocally[i]);
							}
						}
						await this.deleteFormsSavedLocally(formsSavedLocallyToDelete);
						return home;
					} else {
						return home;
					}
				}
			}
		} catch (error) {
			console.log(error);
			throw error;
		}
	}

	async getPublicHolidays(dateFrom, dateTo) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				throw 'NO_NETWORK';
			} else {
				const years = [moment(dateFrom).format('YYYY')];
				if (moment(dateFrom).format('YYYY') !== moment(dateTo).format('YYYY')) {
					years.push(moment(dateTo).format('YYYY'));
				}

				const promises = [];
				for (const i in years) {
					promises.push(this.webService.unauthExternalGet('https://jours-feries-france.antoine-augusti.fr/api/' + years[i]));
				}
				const yearsRetrieved: any = await Promise.all(promises);
				const publicHolidays = [];
				for (const i in yearsRetrieved) {
					for (const j in yearsRetrieved[i]) {
						publicHolidays.push(yearsRetrieved[i][j]);
					}
				}
				return publicHolidays;
			}
		} catch (error) {
			throw null;
		}
	}

	async showSignature(signaturePage, userConnected: UserObject, signature, fileName, title) {
		const signatureModal = await this.modalCtrl.create({
			id: 'SignaturePage',
			component: signaturePage,
			componentProps: {userConnected: userConnected, signatureToUpdate: signature, fileName: fileName, title: title},
			backdropDismiss: false,
			cssClass: 'custom-modal modal-signature'
		});
		await signatureModal.present();
		const result: any = await signatureModal.onWillDismiss();
		return result.data.signature;
	}

	affaireIsDepannage(idAffaire) {
		if (!idAffaire) {
			return false;
		}
		return idAffaire.toString().substring(idAffaire.toString().length - 4) === '0001';
	}

	async getImportPlanning(userId, from, to) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				throw 'NO_NETWORK';
			} else {
				const conf = {
					param_1: 'api',
					param_2: 'auth',
					param_3: 'mystemi',
					param_4: 'feuille_heure',
					param_5: 'import_planning',
					params: {
						user_id: userId,
						from: from,
						to: to
					}
				};
				return await this.webService.get(conf);
			}
		} catch (error) {
			throw null;
		}
	}

	async getNbFeuillesHeuresByStatus(userId) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				throw 'NO_NETWORK';
			} else {
				const conf = {
					param_1: 'api',
					param_2: 'auth',
					param_3: 'mystemi',
					param_4: 'feuille_heure',
					param_5: 'count_by_status',
					params: {}
				};
				if (userId !== null) {
					conf.params['user_id'] = userId;
				}
				return await this.webService.get(conf);
			}
		} catch (error) {
			throw null;
		}
	}

	getSuiviPointages(feuillesHeures: Array<FeuilleHeuresSemaineObject>, users: Array<UserObject>) {
		const suiviPointages = [];
		for (const i in users) {
			suiviPointages.push({
				user: users[i],
				feuilleHeures: null,
			});
		}
		for (const i in feuillesHeures) {
			for (const j in suiviPointages) {
				if (suiviPointages[j].user.id === feuillesHeures[i].user?.id) {
					suiviPointages[j].feuilleHeures = feuillesHeures[i];
					break;
				}
			}
		}
		return suiviPointages.sort((a, b) => {
			if (a.user.actif > b.user.actif) {
				return -1;
			} else if (a.user.actif < b.user.actif) {
				return 1;
			}
			if (a.user.complete_name.toLowerCase() < b.user.complete_name.toLowerCase()) {
				return -1;
			} else if (a.user.complete_name.toLowerCase() > b.user.complete_name.toLowerCase()) {
				return 1;
			}
			return 0;
		});
	}
}
