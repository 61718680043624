import {AppConfig} from './app.config';

export class AppUtils {

	static async delay(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	static async scrollToTop(elementId, content) {
		const scrollableElement = document.getElementById(elementId);
		if (scrollableElement) {
			scrollableElement.classList.remove('height-100');
			await content.scrollToTop();
			scrollableElement.classList.add('height-100');
		}
	}

	static async scrollToElement(elementId, content, platformIsDesktop) {
		await this.delay(10);
		const element = document.getElementById(elementId);
		if (element != null) {
			console.log(element.id);
			console.log(element.offsetTop);
			await content.scrollToPoint(0, element.offsetTop - (platformIsDesktop ? 150 : 50), AppConfig.scrollDuration);
		}
	}

	static scrollIntoView(elementId) {
		const elementSelect = document.getElementById(elementId);
		if (elementSelect) {
			elementSelect.scrollIntoView({behavior: 'auto', block: 'center', inline: 'center'});
		}
	}
}
