import {AffaireTacheObject} from '../commerce/affaire-tache.object';

export class FeuilleHeuresItemObject {
	id: number;
	table = 'feuille_heure_item';
	titre = 'fh item';
	createdAt: string;
	updatedAt: string;
	jour_id: number;
	type: string;
	affaire_tache: AffaireTacheObject;
	type_absence: string;
	temps_travail: number;
	valide: boolean;
	remarque: string;

	constructor(id = null, createdAt = null, updatedAt = null, jour_id = null, type = null, affaire_tache = null, type_absence = null, temps_travail = null, valide = null, remarque = null) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.jour_id = jour_id;
		this.type = type;
		this.affaire_tache = affaire_tache;
		this.type_absence = type_absence;
		this.temps_travail = temps_travail;
		this.valide = valide;
		this.remarque = remarque;
	}

	public static LoadFromJSON(json: any) {
		return new FeuilleHeuresItemObject(
			json['id'],
			json['createdAt'],
			json['updatedAt'],
			json['jour_id'],
			json['type'],
			json['affaire_tache'] ? AffaireTacheObject.LoadFromJSON(json['affaire_tache']) : null,
			json['type_absence'],
			json['temps_travail'],
			json['valide'],
			json['remarque']
		);
	}

	missingMandatoryFields(day) {
		if (this.type === null || this.type === '') {
			return 'Le type d\'activité n\'a pas été renseigné pour une des activités du ' + day + '.';
		}
		if (this.type === 'affaire_tache' && this.affaire_tache === null) {
			return 'La tâche n\'a pas été renseignée pour une des activités du ' + day + '.';
		}
		if (this.type === 'absence' && (this.type_absence === null || this.type_absence === '')) {
			return 'Le type d\'absence n\'a pas été renseigné pour une des activités du ' + day + '.';
		}
		if (this.temps_travail === null) {
			return 'Le temps passé n\'a pas été renseigné pour une des activités du ' + day + '.';
		}
		if (this.type !== 'absence' && this.temps_travail.toString() !== '' && !/^\d+(\.(25|5|75|0)0*)?$/.test(this.temps_travail.toString())) {
			return 'Le format du temps passé doit être .0, .25, .5 ou .75.';
		}
		return null;
	}

	clearRelatedFields(field) {
		switch (field) {
			case 'type':
				if (this.type !== 'absence') {
					this.type_absence = null;
				}
				if (this.type !== 'affaire_tache') {
					this.affaire_tache = null;
				}
				break;
			default :
				break;
		}
	}

	getFormData() {
		return {
			jour_id: this.jour_id,
			type: this.type,
			affaire_tache_id: this.affaire_tache ? this.affaire_tache.id : null,
			type_absence: this.type_absence,
			temps_travail: this.temps_travail,
			valide: this.valide,
			remarque: this.remarque
		};
	}
}
