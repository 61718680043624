import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {IonContent, LoadingController, ModalController, NavParams, Platform} from '@ionic/angular';

import {AlertsService} from '../../../../services/alerts.service';
import {FormsService} from '../../../../services/forms.service';

import {VehiculeObject} from '../../../../objects/forms/parametres/vehicule.object';
import {UserObject} from '../../../../objects/user.object';

import {AppUtils} from '../../../../app.utils';

@Component({
	selector: 'app-vehicule',
	templateUrl: './vehicule.component.html',
	styleUrls: ['./vehicule.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class VehiculeComponent implements OnInit {
	@ViewChild(IonContent, {static: false}) content: IonContent;

	platformIsDesktop: boolean;

	vehicule: VehiculeObject;
	users: Array<UserObject>;

	constructor(private modalCtrl: ModalController, private navParams: NavParams, private platform: Platform, private alertsService: AlertsService, private loadingCtrl: LoadingController, private formsService: FormsService) {
		this.platformIsDesktop = (this.platform.is('desktop') && !this.platform.is('phablet')) || (this.platform.is('tablet') && this.platform.is('mobileweb')) || (this.platform.is('mobile') && this.platform.is('mobileweb'));
	}

	ngOnInit() {
		if (this.navParams.get('vehiculeToUpdate')) {
			this.vehicule = VehiculeObject.LoadFromJSON(this.navParams.get('vehiculeToUpdate'));
		} else {
			this.vehicule = new VehiculeObject();
		}
	}

	async enregistrerVehicule() {
		const missingFieldMessage = this.vehicule.missingMandatoryFields();
		if (missingFieldMessage) {
			await this.alertsService.errorAlert('Donnée(s) manquante(s)', missingFieldMessage);
		} else {
			const loader = await this.loadingCtrl.create({
				spinner: null,
				message: `<div class="spinner-custom"></div>`,
				cssClass: 'loader-stemi'
			});
			await loader.present();
			try {
				const result = await this.formsService.saveForm(this.vehicule);
				this.vehicule = VehiculeObject.LoadFromJSON(result);
				await loader.dismiss();
				await this.alertsService.messageAlert(null, this.vehicule.id !== null ? 'Véhicule mis à jour.' : 'Véhicule crée.');
				await this.closeModal(true, this.vehicule);
			} catch (error) {
				await loader.dismiss();
				await this.alertsService.errorAlert(null, this.alertsService.getErrorMessage(error));
			}
		}
	}

	async addEntretien() {
		this.vehicule.addEntretien();
		await AppUtils.scrollToElement('entretien-' + (this.vehicule.entretiens.length - 1), this.content, this.platformIsDesktop);
	}

	async deleteEntretien(index) {
		const confirmed = await this.alertsService.confirmationAlert(null, 'Êtes-vous sûr(e) de vouloir supprimer cet entretien ?', 'Supprimer');
		if (confirmed) {
			this.vehicule.entretiens.splice(index);
		}
	}

	async addIndisponibilite() {
		this.vehicule.addIndisponibilite();
		await AppUtils.scrollToElement('indisponibilite-' + (this.vehicule.indisponibilites.length - 1), this.content, this.platformIsDesktop);
	}

	async deleteIndisponibilite(index) {
		const confirmed = await this.alertsService.confirmationAlert(null, 'Êtes-vous sûr(e) de vouloir supprimer cette indisponibilité ?', 'Supprimer');
		if (confirmed) {
			this.vehicule.indisponibilites.splice(index);
		}
	}

	async closeModal(refresh = false, vehicule = null) {
		await this.modalCtrl.dismiss({refresh: refresh, vehicule: vehicule}, null, 'VehiculeComponent');
	}
}
