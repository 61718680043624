import * as moment from 'moment';

import {UserObject} from '../../user.object';

import {AppConfig} from '../../../app.config';

export class AffaireTachePlanificationObject {
	id: number;
	table = 'affaire_tache_planification';
	createdAt: string;
	updatedAt: string;
	createdBy: UserObject;
	updatedBy: UserObject;
	id_tache: number;
	date: string;
	ressources: Array<UserObject>;
	nb_ressources: number;
	nb_heures: number;
	status: string;

	tache: {
		affaire: {
			id: string,
			nom: string,
			code_client: string,
			nom_client: string;
		},
		service: string,
		nom: string
	};

	checked: boolean;

	constructor(id = null, createdAt = null, updatedAt = null, createdBy = null, updatedBy = null, id_tache = null, date = null, ressources = [],
				nb_ressources = null, nb_heures = null, status = 'Planifié', tache = null) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.createdBy = createdBy;
		this.updatedBy = updatedBy;
		this.id_tache = id_tache;
		this.date = date;
		this.ressources = ressources;
		this.nb_ressources = nb_ressources;
		this.nb_heures = nb_heures;
		this.status = status;
		this.tache = tache;
	}

	public static LoadFromJSON(json: any) {
		return new AffaireTachePlanificationObject(
			json['id'],
			json['createdAt'],
			json['updatedAt'],
			json['createdBy'] ? UserObject.LoadFromJSON(json['createdBy']) : null,
			json['updatedBy'] ? UserObject.LoadFromJSON(json['updatedBy']) : null,
			json['id_tache'],
			json['date'],
			this.LoadFromArray(json['ressources'], 'ressources'),
			json['nb_ressources'],
			json['nb_heures'],
			json['status'],
			json['tache']
		);
	}

	public static LoadFromArray(arr: any[], type) {
		const ret = [];
		if (arr) {
			for (let i = 0; i < arr.length; i++) {
				switch (type) {
					case 'ressources':
						ret.push(UserObject.LoadFromJSON(arr[i]));
						break;
				}
			}
		}
		return ret;
	}

	getFormData() {
		const ressources = [];
		for (const i in this.ressources) {
			ressources.push(this.ressources[i].id);
		}
		return {
			id_tache: this.id_tache,
			date: this.date ? moment(this.date).format('YYYY-MM-DD') : null,
			ressources: ressources,
			nb_ressources: this.nb_ressources,
			nb_heures: this.nb_heures,
			status: this.status
		};
	}

	getPlanningBackgroundColor(planningType) {
		switch (planningType) {
			case 'general':
				switch (this.status) {
					case 'Prévisionnel' :
						return '#b2d6ff';
					case 'Planifié':
						return '#fff3ae';
					default:
						return '#fff3ae';
				}
			case 'affaires':
				switch (this.tache?.service) {
					case 'etude-elec' :
						return '#afe6fb';
					case 'etude-auto':
						return '#cce970';
					case 'atelier':
						return '#ffe83c';
					case 'chantier':
						return '#ffb390';
				}
				break;
			case 'service':
				if (this.nb_ressources === null) {
					if (this.ressources.length > 0) {
						return '#9bdb94';
					} else {
						return '#ffc78e';
					}
				} else {
					if (this.ressources.length < this.nb_ressources) {
						return '#ffef75';
					} else {
						return '#9bdb94';
					}
				}
		}
	}

	getPlanningBorderColor(planningType) {
		switch (planningType) {
			case 'general':
				switch (this.status) {
					case 'Prévisionnel' :
						return 'transparent';
					case 'Planifié':
						return 'transparent';
					default:
						return 'transparent';
				}
			default:
				return 'transparent';
		}
	}

	getPlanningBorderClass(planningType) {
		switch (planningType) {
			case 'general':
				switch (this.status) {
					case 'Prévisionnel' :
						return '';
					case 'Planifié':
						return '';
					default:
						return '';
				}
			default:
				return '';
		}
	}

	getPlanningTextColor() {
		return '#000000';
	}

	getHeuresPlanifiees() {
		let heuresPlanifiees = 0;
		if (this.nb_ressources !== null) {
			if (this.nb_heures !== null) {
				heuresPlanifiees += (this.nb_heures * this.nb_ressources);
			} else {
				heuresPlanifiees += (AppConfig.heuresTravailJour * this.nb_ressources);
			}
		} else if (this.ressources?.length > 0) {
			if (this.nb_heures !== null) {
				heuresPlanifiees += (this.nb_heures * this.ressources.length);
			} else {
				heuresPlanifiees += (AppConfig.heuresTravailJour * this.ressources.length);
			}
		} else {
			if (this.nb_heures !== null) {
				heuresPlanifiees += this.nb_heures;
			} else {
				heuresPlanifiees += AppConfig.heuresTravailJour;
			}
		}
		return Math.round(heuresPlanifiees * 100) / 100;
	}
}
