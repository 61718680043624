import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {LoadingController, Platform, PopoverController} from '@ionic/angular';

import {Events} from '../../services/events.service';
import {AlertsService} from '../../services/alerts.service';
import {LocalDataService} from '../../services/local-data.service';
import {UsersService} from '../../services/users.service';

import {UserObject} from '../../objects/user.object';

@Component({
	selector: 'app-user-popover',
	templateUrl: './user-popover.component.html',
	styleUrls: ['./user-popover.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class UserPopoverComponent implements OnInit {

	userConnected: UserObject;
	platformIsDesktop: boolean;

	constructor(private platform: Platform, private popoverCtrl: PopoverController, private events: Events, private alertsService: AlertsService, private localDataService: LocalDataService, private loadingCtrl: LoadingController, public usersService: UsersService) {
		this.platformIsDesktop = (this.platform.is('desktop') && !this.platform.is('phablet')) || (this.platform.is('tablet') && this.platform.is('mobileweb')) || (this.platform.is('mobile') && this.platform.is('mobileweb'));
	}

	ngOnInit() {
	}

	async logout() {
		const confirmed = await this.alertsService.confirmationAlert('Êtes-vous sûr(e) de vouloir vous déconnecter ?', 'Les données non enregistrées seront perdues.', 'OUI');
		if (confirmed) {
			const loader = await this.loadingCtrl.create({
				spinner: null,
				message: `<div class="spinner-custom"></div>`,
				cssClass: 'loader-stemi'
			});
			await loader.present();
			await this.popoverCtrl.dismiss();
			await this.localDataService.setItem('logout', true);
			this.events.publish('logout', { loader : loader });
		}
		else this.close();
	}

	async close() {
		await this.popoverCtrl.dismiss();
	}

}
