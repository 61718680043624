import * as moment from 'moment';

import {ServicePipe} from '../../../pipes/service/service';

import {UserObject} from '../../user.object';
import {FeuilleHeuresJourObject} from './feuille-heures-jour.object';

export class FeuilleHeuresSemaineObject {
	id: number;
	table = 'feuille_heure_semaine';
	titre = 'fh semaine';
	createdAt: string;
	updatedAt: string;
	user: UserObject;
	first_day_week: string;
	status: string;
	days: Array<FeuilleHeuresJourObject>;

	checked: boolean;

	servicesConcernes: Array<{service: string, valide: boolean}>;

	// status
	//
	// draft : Brouillon
	// done : Terminée
	// validated : Validée
	// verified : Vérifiée

	constructor(id = null, createdAt = null, updatedAt = null, user = null, first_day_week = null, status = 'draft',
				days = [new FeuilleHeuresJourObject(), new FeuilleHeuresJourObject(), new FeuilleHeuresJourObject(), new FeuilleHeuresJourObject(), new FeuilleHeuresJourObject(), new FeuilleHeuresJourObject(), new FeuilleHeuresJourObject()]) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.user = user;
		this.first_day_week = first_day_week;
		this.status = status;
		this.days = days.sort((a: FeuilleHeuresJourObject, b: FeuilleHeuresJourObject) => {
			if (moment(a.date).isBefore(moment(b.date))) {
				return -1;
			} else if (moment(a.date).isAfter(moment(b.date))) {
				return 1;
			} else {
				return 0;
			}
		});
		this.setServicesConcernes();
	}

	public static LoadFromJSON(json: any) {
		return new FeuilleHeuresSemaineObject(
			json['id'],
			json['createdAt'],
			json['updatedAt'],
			json['user'] ? UserObject.LoadFromJSON(json['user']) : null,
			json['first_day_week'],
			json['status'],
			this.LoadFromArray(json['days'])
		);
	}

	public static LoadFromArray(arr: any[]) {
		const ret = [];
		if (arr) {
			for (let i = 0; i < arr.length; i++) {
				ret.push(FeuilleHeuresJourObject.LoadFromJSON(arr[i]));
			}
		}
		return ret;
	}

	missingMandatoryFields() {
		if (this.user === null) {
			return 'L\'utilisateur n\'a pas été renseigné.';
		}
		for (const i in this.days) {
			const missingFieldMessage = this.days[i].missingMandatoryFields();
			if (missingFieldMessage) {
				return missingFieldMessage;
			}
		}
		return null;
	}

	updateFeuilleHeuresDays(firstDayOfWeek) {
		if (firstDayOfWeek) {
			this.first_day_week = firstDayOfWeek;
			this.days[0].date = firstDayOfWeek;
			this.days[1].date = moment(firstDayOfWeek).add(1, 'day').format();
			this.days[2].date = moment(firstDayOfWeek).add(2, 'day').format();
			this.days[3].date = moment(firstDayOfWeek).add(3, 'day').format();
			this.days[4].date = moment(firstDayOfWeek).add(4, 'day').format();
			this.days[5].date = moment(firstDayOfWeek).add(5, 'day').format();
			this.days[6].date = moment(firstDayOfWeek).add(6, 'day').format();
		}
	}

	hasItems() {
		if (this.days[0].items && this.days[0].items.length > 0) {
			return true;
		} else if (this.days[1].items && this.days[1].items.length > 0) {
			return true;
		} else if (this.days[2].items && this.days[2].items.length > 0) {
			return true;
		} else if (this.days[3].items && this.days[3].items.length > 0) {
			return true;
		} else if (this.days[4].items && this.days[4].items.length > 0) {
			return true;
		} else if (this.days[5].items && this.days[5].items.length > 0) {
			return true;
		} else if (this.days[6].items && this.days[6].items.length > 0) {
			return true;
		} else {
			return false;
		}
	}

	allItemsValidated() {
		for (const i in this.days) {
			for (const j in this.days[i].items) {
				if (this.days[i].items[j].type === 'affaire_tache' && !this.days[i].items[j].valide) {
					return false;
				}
			}
		}
		return true;
	}

	getDateEnd() {
		if (this.first_day_week) {
			return moment(this.first_day_week).endOf('week').format();
		} else {
			return null;
		}
	}

	setServicesConcernes() {
		this.servicesConcernes = [];
		for (const i in this.days) {
			for (const j in this.days[i].items) {
				if (this.days[i].items[j].type === 'affaire_tache' && this.days[i].items[j].affaire_tache) {
					let serviceFound = false;
					for (const k in this.servicesConcernes) {
						if (this.days[i].items[j].affaire_tache.service === this.servicesConcernes[k].service) {
							serviceFound = true;
							if (!this.days[i].items[j].valide) {
								this.servicesConcernes[k].valide = false;
							}
							break;
						}
					}
					if (!serviceFound) {
						this.servicesConcernes.push({
							service: this.days[i].items[j].affaire_tache.service,
							valide: this.days[i].items[j].valide
						});
					}
				}
			}
		}
	}

	getFormData() {
		return {
			user_id: this.user ? this.user.id : null,
			first_day_week: this.first_day_week ? moment(this.first_day_week).format() : null,
			status: this.status
		};
	}
}
